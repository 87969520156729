import React from "react";
import "../../Styles/ProfileStatus.scss";
import { useNavigate } from "react-router-dom";

const ProfileStatus = () => {
  const navigate = useNavigate();
  const status:number = 100;

  function navigate_profile() {
    sessionStorage.setItem("home_navigation", "/Profile");
    navigate("/profile");
  }

  return (
    <>
   {status != 100 ? (
       <section id="profileStatus_container">
     
       <div className="d-flex justify-content-around align-items-center">
         <div>
           <h1 className="fw-bold">{status}%</h1>
           <h3 className="fw-bold">of your profile is complete</h3>
         </div>
         <div>
           <h3 className="fw-bold">Complete your profile to ask any question to Ahsa!</h3>
           <p>
             Without completing the profile you can't ask custom questions apart
             from the pre-defined ones.
           </p>
           <div className="d-flex justify-content-between align-items-center">
             <button onClick={() => navigate_profile()} className="btn btn-primary fw-bolder">Complete Profile</button>
             <h5 className="fw-bold">
               Why is it important ?{" "}
               <span>
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   width="1.2em"
                   height="1.2em"
                   viewBox="0 0 24 24"
                 >
                   <g
                     fill="none"
                     stroke="black"
                     stroke-linecap="round"
                     stroke-width="2"
                   >
                     <path
                       stroke-dasharray="60"
                       stroke-dashoffset="60"
                       d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
                     >
                       <animate
                         fill="freeze"
                         attributeName="stroke-dashoffset"
                         dur="0.5s"
                         values="60;0"
                       />
                     </path>
                     <path
                       stroke-dasharray="8"
                       stroke-dashoffset="8"
                       d="M12 7V13"
                     >
                       <animate
                         fill="freeze"
                         attributeName="stroke-dashoffset"
                         begin="0.6s"
                         dur="0.2s"
                         values="8;0"
                       />
                     </path>
                   </g>
                   <circle cx="12" cy="17" r="1" fill="black" fill-opacity="0">
                     <animate
                       fill="freeze"
                       attributeName="fill-opacity"
                       begin="0.8s"
                       dur="0.4s"
                       values="0;1"
                     />
                   </circle>
                 </svg>
               </span>
             </h5>
           </div>
         </div>
       </div>
     </section>
   ):(  "")}
  
    </>
  );
};

export default ProfileStatus;
