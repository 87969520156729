import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Styles/Sidebar.scss";
import { stateContext } from "../utils/stateContext";

const Sidebar = () => {
  const {
    state: {
      user_Data: { user_name, role_id, user_id },
    },
    dispatch,
  } = useContext(stateContext);
  const navigate = useNavigate();

  const [isExpanded, setIsExpanded] = useState(false);

  const home_navigation = sessionStorage.getItem("home_navigation");
  // console.log(home_navigation, "home_navigation");
  const pathLocal: any = sessionStorage.getItem("pathLocal");
  const JosonPath = JSON.parse(pathLocal);

  // Initialize selectPath
  const [selectPath, setSelectPath] = useState<string>(
    JosonPath ? JosonPath : "/"
  );
  // console.log(selectPath, "selectPath");

  // Initialize activePath
  const [activePath, setActivePath] = useState<string>(
    home_navigation ? home_navigation : selectPath
  );
  // console.log(activePath, "activePath");

  useEffect(() => {
    // Update activePath if home_navigation changes
    setActivePath(home_navigation ? home_navigation : selectPath);
  }, [home_navigation]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNavigation = (path: any) => {
    console.log(path, "path");

    setActivePath(path);
    setSelectPath(path);
    navigate({ pathname: path });
    sessionStorage.removeItem("home_navigation");
  };

  // logout function async function logout() {
  const logOut = () => {
    const popupData = {
      showPopup: true,
      user: user_id,
      type: "Logout",
    };
    const popupDataString = JSON.stringify(popupData);
    sessionStorage.setItem("Popup", popupDataString);
    dispatch({
      type: "POPUP",
      payload: popupData,
    });
    dispatch({
      type: "LOGS_VIEW",
      payload: false,
    });
  };
  // const [newChat, setNewChat] = useState(true);
  // const handleNewChat = () => {
  //   setNewChat((prevNewChat) => {
  //     const toggledNewChat = !prevNewChat;
  //     console.log(toggledNewChat, "toggledNewChat");

  //     dispatch({ type: "NEW_CHAT", payload: toggledNewChat });

  //     return toggledNewChat;
  //   });
  // };

  const handleNewChat = () => {
    dispatch({ type: "NEW_CHAT", payload: true });
  };

  const getStrokeColor = (path: any) =>
    activePath === path ? "white" : "black";

  return (
    // proper desigon in side bar for
    <section className=" section-sidebar col-lg-2">
      <div className="sidebar-h6 d-flex  position-relative flex-column justify-content-between ">
        <>
        <div className="sidebar_logo col-lg-2">
          <img
           src={require("../assets/logo/Ashaa_logo.png")}
           width="90%"
           alt="world image"
         />
        </div>
        <div className="w-100 position-absolute logo-hr">
          <hr />
          </div>


        </>
        <div className="mildgray sidebar_path col-lg-2">
          {/* <h6
            className={activePath === "/" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/")}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill={getStrokeColor("/")}
                  d="m12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81zM12 3L2 12h3v8h6v-6h2v6h6v-8h3z"
                />
              </svg>
            </span>
            Home
          </h6> */}

          {/*  chat box start */}
          <h6
            className={activePath === "/" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/")}
            style={{ position: "relative" }}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke={getStrokeColor("/")}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 9h8m-8 3h8m-8 3h3m10-3a9 9 0 0 1-13.815 7.605L3 21l1.395-4.185A9 9 0 1 1 21 12"
                />
              </svg>
            </span>
            Chat
            {/* {activePath === "/" && (
              <span
                className="mx-2 position-absolute  end-0"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                // , handleNewChat()
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5em"
                  height="2em"
                  viewBox="0 0 24 24"
                >
                 
                  <g
                    fill="none"
                    stroke={getStrokeColor("/")}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  >
                    <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                    <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                  </g>
                </svg>
              </span>
            )} */}
          </h6>

          {/* chatbot end  */}

          {/* <h6
            className={activePath === "/Profile" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/Profile")}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke={getStrokeColor("/Profile")}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 9h3m-3 3h3m-3 3h3m-6 1c-.306-.613-.933-1-1.618-1H7.618c-.685 0-1.312.387-1.618 1M4 5h16a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1m7 5a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                />
              </svg>
            </span>
            Profile
          </h6> */}
          {/* <h6
            className={activePath === "/Reports" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/Reports")}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 34 32"
              >
                <g fill={getStrokeColor("/Reports")}>
                  <path d="M1.512 28H19.5c.827 0 1.5-.673 1.5-1.5v-19c0-.023-.01-.043-.013-.065a.426.426 0 0 0-.013-.062a.488.488 0 0 0-.122-.227L13.853.147a.507.507 0 0 0-.289-.135C13.543.01 13.523 0 13.5 0H1.506C.676 0 0 .673 0 1.5v25c0 .827.678 1.5 1.512 1.5M14 1.707L19.293 7H14.5a.5.5 0 0 1-.5-.5zM1 1.5c0-.276.227-.5.506-.5H13v5.5c0 .827.673 1.5 1.5 1.5H20v18.5a.5.5 0 0 1-.5.5H1.512A.506.506 0 0 1 1 26.5z" />
                  <path d="M4.5 12h12a.5.5 0 0 0 0-1h-12a.5.5 0 0 0 0 1m0 4h12a.5.5 0 0 0 0-1h-12a.5.5 0 0 0 0 1m0-8h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0 0 1m0 12h12a.5.5 0 0 0 0-1h-12a.5.5 0 0 0 0 1m0 4h12a.5.5 0 0 0 0-1h-12a.5.5 0 0 0 0 1" />
                  <path d="M21.5 5H26v5.5c0 .827.673 1.5 1.5 1.5H33v18.5a.5.5 0 0 1-.5.5H14.512a.506.506 0 0 1-.512-.5v-1a.5.5 0 0 0-1 0v1c0 .827.678 1.5 1.512 1.5H32.5c.827 0 1.5-.673 1.5-1.5v-19c0-.023-.01-.043-.013-.065a.426.426 0 0 0-.013-.062a.488.488 0 0 0-.122-.227l-6.999-6.999a.491.491 0 0 0-.289-.134C26.543 4.01 26.523 4 26.5 4h-5a.5.5 0 0 0 0 1m6 6a.5.5 0 0 1-.5-.5V5.707L32.293 11z" />
                  <path d="M23.5 16h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0 0 1m0 4h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0 0 1m0 4h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0 0 1m0 4h6a.5.5 0 0 0 0-1h-6a.5.5 0 0 0 0 1" />
                </g>
              </svg>
            </span>
            Reports
          </h6> */}
          <h6
            className={activePath === "/Logs" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/Logs")}
            // style={{display:"flex",alignItems:"center"}}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 48 48"
              >
                <g
                  fill="none"
                  stroke={getStrokeColor("/Logs")}
                  stroke-linejoin="round"
                  stroke-width="4"
                >
                  <path d="M13 10h28v34H13z" />
                  <path
                    stroke-linecap="round"
                    d="M35 10V4H8a1 1 0 0 0-1 1v33h6m8-16h12m-12 8h12"
                  />
                </g>
              </svg>
            </span>
            <span className="d-inline">
              {/* <Logs/> */}
              Logs
            </span>
          </h6>
          {/* <h6
            className={activePath === "/Download" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/Download")}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 48 48"
              >
                <g
                  fill="none"
                  stroke={getStrokeColor("/Download")}
                  stroke-linejoin="round"
                  stroke-width="4"
                >
                  <path d="M12 4h24v20H12z" />
                  <path stroke-linecap="round" d="M24 24v10m-6-6l6 6 6-6" />
                </g>
              </svg>
            </span>
            <span className="d-inline">Download</span>
          </h6> */}

          {/* {role_id != 3 &&
          <h6
            className={activePath === "/Users" ? "sidebar_active" : ""}
            onClick={() => handleNavigation("/Users")}
          >
            <span className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.5em"
                height="2em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke={getStrokeColor("/Users")}
                  stroke-width="2"
                >
                  <path
                    stroke-linejoin="round"
                    d="M4 18a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z"
                  />
                  <circle cx="12" cy="7" r="3" />
                </g>
              </svg>
            </span>
            Users
          </h6>
} */}
        </div>

        <div className="sidebar_bottom col-lg-2">
          <hr></hr>
          <div className="d-flex justify-content-around align-items-center ms-1">
            <h6 className="me-2">
              <img
                src="https://th.bing.com/th/id/OIP.Gfp0lwE6h7139625a-r3aAHaHa?w=205&h=205&c=7&r=0&o=5&pid=1.7"
                width={25}
                height={25}
                className="sidebar_image"
                alt="user"
              />
            </h6>
            <h6 className="mb-3">{user_name}</h6>
            <p>
              <span className="me-2">
                {!isExpanded ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleToggle}
                    width="1.4em"
                    height="2em"
                    viewBox="0 0 24 24"
                    className="cursor-pointer"
                  >
                    <path
                      fill="none"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="m7 10l5 5l5-5"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.4em"
                    height="2em"
                    viewBox="0 0 24 24"
                    onClick={handleToggle}
                    className="cursor-pointer"
                  >
                    <path
                      fill="none"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="m17 14l-5-5l-5 5"
                    />
                  </svg>
                )}
              </span>
            </p>
          </div>
          {isExpanded && (
            <>
              {" "}
              {/* <h6 onClick={() => navigate({ pathname: "/" })}>
                <span className="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="black"
                      d="M12 4c4.411 0 8 3.589 8 8s-3.589 8-8 8s-8-3.589-8-8s3.589-8 8-8m0-2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2m4 8a4 4 0 0 0-8 0h2c0-1.103.897-2 2-2s2 .897 2 2s-.897 2-2 2a1 1 0 0 0-1 1v2h2v-1.141A3.991 3.991 0 0 0 16 10m-3 6h-2v2h2z"
                    />
                  </svg>
                </span>
                Help
              </h6>
              <h6 onClick={() => navigate({ pathname: "/settings" })}>
                <span className="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="black"
                      d="M12 8a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 2a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2m-2 12c-.25 0-.46-.18-.5-.42l-.37-2.65c-.63-.25-1.17-.59-1.69-.99l-2.49 1.01c-.22.08-.49 0-.61-.22l-2-3.46a.493.493 0 0 1 .12-.64l2.11-1.66L4.5 12l.07-1l-2.11-1.63a.493.493 0 0 1-.12-.64l2-3.46c.12-.22.39-.31.61-.22l2.49 1c.52-.39 1.06-.73 1.69-.98l.37-2.65c.04-.24.25-.42.5-.42h4c.25 0 .46.18.5.42l.37 2.65c.63.25 1.17.59 1.69.98l2.49-1c.22-.09.49 0 .61.22l2 3.46c.13.22.07.49-.12.64L19.43 11l.07 1l-.07 1l2.11 1.63c.19.15.25.42.12.64l-2 3.46c-.12.22-.39.31-.61.22l-2.49-1c-.52.39-1.06.73-1.69.98l-.37 2.65c-.04.24-.25.42-.5.42zm1.25-18l-.37 2.61c-1.2.25-2.26.89-3.03 1.78L5.44 7.35l-.75 1.3L6.8 10.2a5.55 5.55 0 0 0 0 3.6l-2.12 1.56l.75 1.3l2.43-1.04c.77.88 1.82 1.52 3.01 1.76l.37 2.62h1.52l.37-2.61c1.19-.25 2.24-.89 3.01-1.77l2.43 1.04l.75-1.3l-2.12-1.55c.4-1.17.4-2.44 0-3.61l2.11-1.55l-.75-1.3l-2.41 1.04a5.42 5.42 0 0 0-3.03-1.77L12.75 4z"
                    />
                  </svg>
                </span>
                Settings
              </h6> */}
              <h6 onClick={() => logOut()}>
                <span className="mx-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="2em"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <path d="M10 8V6a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-7a2 2 0 0 1-2-2v-2" />
                      <path d="M15 12H3l3-3m0 6l-3-3" />
                    </g>
                  </svg>
                </span>
                Logout
              </h6>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
