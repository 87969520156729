import React, { useEffect, useState } from 'react';

interface TypingTextProps {
  text: string;
  speed?: number; // Speed in milliseconds per letter
  onComplete? : () => void;
}

const TypingText: React.FC<TypingTextProps> = ({ text = '', speed = 50, onComplete = () => {}   }) => {

  const [displayedText, setDisplayedText] = useState('');

  
useEffect(() => {
  if (text.length === 0) return; // Avoid running if text is empty
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < text.length) {
        setDisplayedText(text.substring(0, index + 1)); // Update displayedText with substring of text
        index += 1;
      } else {
        // console.log('entre the else condition');
        
        clearInterval(intervalId);
        setTimeout(() => {
          onComplete();
        }, 5000);
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed]);

  return <span>{displayedText}</span>;
};

export default TypingText;
