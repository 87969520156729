import axios from "axios";
import * as crypto from "crypto-js";
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/Login.scss";
import { stateContext } from "../utils/stateContext";
import PasswordInput from "./PasswordInput";
import PrimaryBtn from "./PrimaryBtn";
import Toast from "./Toast";

const ForgotPassword: React.FC = () => {
    const { state, dispatch } = useContext(stateContext);
    console.log(state);
    // useLocation provides access to the current URL
    const location = useLocation();
    console.log(location, "Current URL location");

    // Parse URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token"); // Extract the email query parameter
    console.log(token, "token"); // Extract the password query parameter

    const [userMail, setUserMail] = useState<string>("");
    console.log(userMail, "userMail");

    const [showErrorToast, setShowErrorToast] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<any>();
    console.log(showErrorMessage);




    useEffect(() => {
        // Define the async function inside the useEffect
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/resetToken`,
                    {
                        params: {
                            token: token, // Send the token in the query string
                        },
                    }
                );

                if (response.status === 200) {
                    // Show success alert if the status is 200
                    //   alert("Success! Email ID retrieved.");
                    setUserMail(response.data.email_id);
                } else {
                    // Show failure alert for any other status
                    //   alert("Failure! Something went wrong.");
                }

                console.log(response.data, "response.data");

            } catch (error: any) {
                // Check if the error has a response and handle 400 errors specifically
                if (error.response && error.response.status === 400) {
                    // Handle 400 error specifically
                    //   alert(`Failure! ${error.response.data.message}`);
                    setShowErrorToast(true);
                    setShowErrorMessage({
                        statusCode: 400,
                        status: `${error.response.data.status}`,
                        message: `${error.response.data.message}`,
                    });;
                    // Show the toast message for 5 seconds, then navigate
                    setTimeout(() => {
                        setShowToast(false); // Hide the toast message
                        navigate("/EmailAddress"); // Navigate to the desired route
                    }, 3000); // 5000 milliseconds = 5 seconds
                } else {
                    // Handle other errors (network issues, server errors, etc.)
                    //   alert("Error occurred while fetching data!");
                    console.error("Fetch error:", error);
                }

            }
        };

        // Call the async function
        fetchData();

        // Dependency array - the effect will re-run if the token changes
    }, [token]); // Ensure `token` is in the dependency array

    const [userLoginInfo, setUserLoginInfo] = useState<any>({
        password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isValidPassword, setIsValidPassword] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        specialChar: false,
        numeric: false
    });
    const navigate = useNavigate();
    const [showToast, setShowToast] = useState(false);
    const [forceLogout, setForceLogout] = useState(false);

    const [successMessage, setSuccessMessage] = useState("");
    console.log(successMessage, "successMessage");


    // Function to validate password based on requirements
    const validatePassword = (password: string) => {
        const updatedValidation = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
            numeric: /[0-9]/.test(password),
        };
        setIsValidPassword(updatedValidation);
    };



    const handleClick = async (e: React.FormEvent) => {
        e.preventDefault();

        const newErrors: { [key: string]: string } = {};
        let isValid = true;

        // Validate password and confirm password fields
        if (!userLoginInfo.password) {
            newErrors.password = "Please enter your password";
            isValid = false;
        }
        if (!userLoginInfo.confirmPassword) {
            newErrors.confirmPassword = "Please enter your confirm password";
            isValid = false;
        }

        // Validate password confirmation
        if (userLoginInfo.password !== userLoginInfo.confirmPassword) {
            newErrors.confirmPassword = "Passwords do not match";
            isValid = false;
        }

        // Set validation errors and exit if validation fails
        if (!isValid) {
            setErrors(newErrors);
            return;
        }

        console.log(userLoginInfo, "userLoginInfo");


        const inputDateString = new Date().toISOString();
      const utcDate = inputDateString.slice(0, 19).replace("T", " ");

        try {
            console.log(utcDate, "utcDate");

            // Fetch user data from API based on email ID
            const userDataFromAPI = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/users/${userMail}`
            );
            console.log(userDataFromAPI, "userDataFromAPI");

            // Check if user data exists and the account creation date is available
            if (
                userDataFromAPI?.data?.length > 0 &&
                userDataFromAPI?.data[0]?.created_on
            ) {
                //Code below works for deployment

                const dateString = userDataFromAPI?.data[0]?.created_on;
                const utcDate = dateString.slice(0, 19).replace("T", " ");
                console.log(utcDate, "utcDate");

                const hash = crypto
                    .SHA512(userLoginInfo?.password + utcDate)
                    .toString();


                // code below works for local code 

                // const dateString = userDataFromAPI?.data[0]?.created_on;
                // const dateObject = new Date(dateString);
                // const formattedTime = `${dateObject.getFullYear()}-${String(
                //     dateObject.getMonth() + 1
                // ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(2, "0")} ${String(
                //     dateObject.getHours()
                // ).padStart(2, "0")}:${String(dateObject.getMinutes()).padStart(
                //     2, "0"
                // )}:${String(dateObject.getSeconds()).padStart(2, "0")}`;

                // // Create the hash using the password and the formatted time
                // const hash = crypto.SHA512(userLoginInfo.password + formattedTime).toString();
                // console.log(hash, "hash");

                // Make the login request
                const loginResponse = await axios.put(
                    `${process.env.REACT_APP_BASE_URL}/resetPassword`,
                    {
                        // email_id: userMail,
                        newPassword: hash,
                        token: token,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        withCredentials: true,
                    }
                );
                console.log(loginResponse, "loginResponse");
                console.log(loginResponse.data, "loginResponse.data");

                setSuccessMessage(loginResponse.data);
                // Handle successful login
                if (loginResponse.status === 200) {
                    console.log("Forgot password successful");
                    setShowToast(true);

                    setTimeout(() => {
                        navigate("/"); // Navigate to the login page
                    }, 3000);
                } else {
                    console.log(`Error logging in. Status: ${loginResponse.status}`);
                    //   alert(`Error logging in. Status: ${loginResponse.status}`);
                }
            } else {
                // Handle case where user data is not found
                console.log("User not found", userDataFromAPI);
                alert("User not found");
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                // Handle 400 error specifically
                //   alert(`Failure! ${error.response.data.message}`);
                setShowErrorToast(true);
                setShowErrorMessage({
                    statusCode: 400,
                    status: `${error.response.data.status}`,
                    message: `${error.response.data.message}`,
                });;
                // Show the toast message for 5 seconds, then navigate
                setTimeout(() => {
                    setShowToast(false); // Hide the toast message
                    navigate("/EmailAddress"); // Navigate to the desired route
                }, 3000); // 5000 milliseconds = 5 seconds
            } else {
                // Handle other errors (network issues, server errors, etc.)
                //   alert("Error occurred while fetching data!");
                console.error("Fetch error:", error);
            }
            setErrors(newErrors);
        }
    };

    const toastRefresh = () => {
        setShowToast(false);
        // setErrorMessage("");
    };

    return (
        <div className="login">
            <div className="login-container p-4" style={{   height: !userLoginInfo.password ? "30em" : "37em"}}>
                <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="2em"
                        height="2em"
                        viewBox="0 0 24 24"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        onClick={() => navigate("/")}
                    >
                        <path
                            fill="black"
                            d="M10.928 21a2.978 2.978 0 0 1-2.121-.879L1.686 13l7.121-7.121c1.133-1.134 3.109-1.134 4.242 0c.566.564.879 1.317.879 2.119c0 .746-.27 1.451-.764 2.002H18c1.654 0 3 1.346 3 3s-1.346 3-3 3h-4.836c.493.549.764 1.252.764 1.998a2.977 2.977 0 0 1-.879 2.124a2.983 2.983 0 0 1-2.121.878m-6.414-8l5.707 5.707a1.023 1.023 0 0 0 1.414 0c.189-.189.293-.441.293-.708s-.104-.517-.291-.705L8.342 14H18a1.001 1.001 0 0 0 0-2H8.342l3.293-3.293a.996.996 0 0 0 .001-1.413a1.023 1.023 0 0 0-1.415-.001z"
                        />
                        <title>Back Login Page</title>
                    </svg>
                </span>
                <h4
                    className="text-center my-1 pb-3"
                    style={{ letterSpacing: "1px", fontWeight: "bold" }}
                >
                    Forgot Password
                </h4>
                {/* Show password requirements only when the user starts typing */}
                

                <Form onSubmit={handleClick}>
                    <Form.Group className="mb-2">
                        <Form.Label>
                            Password<span className="text-danger m-2">*</span>
                        </Form.Label>
                        <PasswordInput
                            // type="password"
                            // placeholder="Enter password"
                            // size="lg"
                            value={userLoginInfo.password}
                            onChange={(e: any) => {
                                const value = e.target.value;
                                setUserLoginInfo({ ...userLoginInfo, password: value });
                                validatePassword(value); // Dynamically validate password on input
                            }}
                            
                            
                        />
                      {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                )}
                {!userLoginInfo.password && (
                    <br></br>
                )}
                
                {/* Dynamically show password requirements */}
                {userLoginInfo.password && (
                    <>
                        <div className="password-requirements">
                            <p style={{ color: isValidPassword.length ? 'green' : 'red' }}>
                                <span className={isValidPassword.length ? 'fa fa-check-circle' : 'fa fa-times-circle'}></span>
                                {' '}At least 8 characters
                            </p>
                            <p style={{ color: isValidPassword.uppercase ? 'green' : 'red' }}>
                                <span className={isValidPassword.uppercase ? 'fa fa-check-circle' : 'fa fa-times-circle'}></span>
                                {' '}Contains an uppercase letter
                            </p>
                            <p style={{ color: isValidPassword.lowercase ? 'green' : 'red' }}>
                                <span className={isValidPassword.lowercase ? 'fa fa-check-circle' : 'fa fa-times-circle'}></span>
                                {' '}Contains a lowercase letter
                            </p>
                            <p style={{ color: isValidPassword.specialChar ? 'green' : 'red' }}>
                                <span className={isValidPassword.specialChar ? 'fa fa-check-circle' : 'fa fa-times-circle'}></span>
                                {' '}Contains a special character
                            </p>
                            <p style={{ color: isValidPassword.numeric ? 'green' : 'red' }}>
                                <span className={isValidPassword.numeric ? 'fa fa-check-circle' : 'fa fa-times-circle'}></span>
                                {' '}Contains a number
                            </p>
                        </div>
                       
                    </>
                )}
                {/* <br></br> */}
                    </Form.Group>
                    <Form.Group className="">
                        <Form.Label>
                            Confirm Password<span className="text-danger m-2">*</span>
                        </Form.Label>
                        <PasswordInput
                            // type="password"
                            // placeholder="Confirm password"
                            // size="lg"
                            value={userLoginInfo.confirmPassword}
                            onChange={(e: any) =>
                                setUserLoginInfo({
                                    ...userLoginInfo,
                                    confirmPassword: e.target.value,
                                })
                            }
                        />
                        {errors.confirmPassword && (
                            <p className="text-danger">{errors.confirmPassword}</p>
                        )}
                         {!userLoginInfo.password && (
                            <>
                    <br></br>
                    {/* <br></br> */}
                    </>
                )}
                    </Form.Group>
                    {/* forgot password and create account is added in this code */}
                    <div className="d-flex justify-content-end align-items-center">

                        {/* <div>
              <Form.Check
                type="checkbox"
                id="customControlInline"
                label="Remember me"
              />
            </div> */}
                    </div>
                    {!errors.confirmPassword && (
                            <>
                    <br></br>
                    </>
                )}
                    <PrimaryBtn style={{ width: "100%" }} type="submit" title="">
                        Change Password
                    </PrimaryBtn>
                    {showToast && (
                        <Toast messages={successMessage} onClose={() => toastRefresh()} />
                    )}
                    {showErrorToast && (
                        <Toast messages={showErrorMessage} onClose={() => toastRefresh()} />
                    )}

                </Form>
                {/* registeration */}
            </div>
            <>
                {forceLogout && (
                    <div className="force-popup">
                        <div className="Popup">
                            <h3 className="mb-4">Logout</h3>
                            <h6>Are you sure you want to logout?</h6>
                            <div className="text-end mt-5">
                                <PrimaryBtn
                                    onClick={() => setForceLogout(false)}
                                    style={{
                                        background: "none",
                                        color: "var(--Colours-Primary-colour-Blue-500)",
                                        border: "1px solid var(--Colours-Primary-colour-Blue-500)",
                                    }}
                                    title=""
                                >
                                    Cancel
                                </PrimaryBtn>
                                <PrimaryBtn
                                    style={{ marginLeft: "1rem" }}
                                    // onClick={handleSubmit}
                                    title=""
                                >
                                    Yes
                                </PrimaryBtn>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </div>
    );
};

export default ForgotPassword;
