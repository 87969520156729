import React, { useState } from "react";
import PrimaryBtn from "./PrimaryBtn";
import cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import Toast from "./Toast";
import UseFetch from "../utils/useFetch";
import axios from "../api/axios";
const Logout = ({
  close,
  user_id,
  dispatch,
}: {
  close: any;
  user_id: any;
  dispatch: any;
}) => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const { apiCall: updateLogOutTime,message: logOut } = UseFetch("/users/logout", "PUT");
  // const { apiCall: cookieRomove } = UseFetch("/logout", "PATCH");
  // const handleSubmit = async () => {
  //   try {
  //     const inputDateString = new Date().toISOString();
  //     const utcDate = inputDateString.slice(0, 19).replace("T", " ");
  //     const logOutPayload = {
  //       logout_time: utcDate,
  //     };
  //     await updateLogOutTime(logOutPayload, user_id);
  //     // await cookieRomove();
  //     await axios.post('https://clownfish-app-2-maedl.ondigitalocean.app/api/logout', {}, {
  //       withCredentials: true, // Include cookies in the request
  //     });
  //     sessionStorage.clear();
  //     cookies.remove("jwt");
  //     dispatch({
  //       type: "LOGOUT",
  //     });
  //     close();
  //     navigate("/");
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  const handleSubmit = async () => {
    try {
      // Get the current date and format it for UTC
      const inputDateString = new Date().toISOString();
      const utcDate = inputDateString.slice(0, 19).replace("T", " ");
      const logOutPayload = {
        logout_time: utcDate,
      };
  
      // Update logout time on the server
      await updateLogOutTime(logOutPayload, user_id);
  
      // Make a request to logout the user and clear cookies on the server
      await axios.patch(`${process.env.REACT_APP_BASE_URL}/logout`, {}, {
        withCredentials: true, // Include cookies in the request
      });
  
      // Clear session storage
      sessionStorage.clear();
  
      // Remove the JWT cookie from the client
      cookies.remove("jwt", { path: '/', sameSite: 'None', secure: true }); // Ensure you set the same attributes as when setting the cookie
  
      // Dispatch logout action to update the global state
      dispatch({
        type: "LOGOUT",
      });
  
      // Close any modal or perform cleanup
      close();
  
      // Navigate to the home page or desired route
      navigate("/");
    } catch (error) {
      console.error('Logout error:', error); // Log any errors encountered during the process
    }
  };
  
  return (
    <div>
      <h3 className="mb-4">Logout</h3>
      <h6>Are you sure you want to logout?</h6>
      <div className="text-end mt-5">
        <PrimaryBtn
          onClick={() => close()}
          style={{
            background: "none",
            color: "var(--Colours-Primary-colour-Blue-500)",
            border: "1px solid var(--Colours-Primary-colour-Blue-500)",
          }}
          title=''
        >
          Cancel
        </PrimaryBtn>
        <PrimaryBtn style={{ marginLeft: "1rem" }} onClick={handleSubmit}title=''>
          Yes
        </PrimaryBtn>
      </div>
      {showToast && (
        <Toast messages={logOut} onClose={() => setShowToast(false)} />
      )}
    </div>
  );
};

export default Logout;
