import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../common/PrimaryBtn";
import Toast from "../../common/Toast";
import axios from "../../api/axios";
import Loading from "../../common/Loading";

const EmailAddress = () => {
  const [showToast, setShowToast] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>();
  const [userLoginInfo, setUserLoginInfo] = React.useState({
    email_id: "",
  });
  const [errors, setErrors] = React.useState({
    email_id: "",
  });
  const [loading, setLoading] = useState(false); // Manage loading state
  console.log(loading, "loading");
  const [message, setMessage] = React.useState("");

  const navigate = useNavigate();
  const handleClick = async (event: any) => {
    event.preventDefault();

    try {
      // Check if email_id is empty and set an error
      if (userLoginInfo.email_id === "") {
        setErrors({
          ...errors,
          email_id: "Please enter your email",
        });
        setLoading(false); // Stop loading if validation fails
        return; // Stop further execution if email is empty
      }

      // Fetch user data from API
      const userDataFromAPI = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users/${userLoginInfo?.email_id}`
      );

      // If no user is found, set an error for invalid email
      if (userDataFromAPI?.data?.length === 0) {
        setErrors({
          ...errors,
          email_id: "Please enter a valid email",
        });
        return; // Stop further execution if the email is invalid
      }
      setLoading(true);
      // Make the forgot password request
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/forgotPassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email_id: userLoginInfo.email_id,
          }),
        }
      );

      const result = await response.json();
      console.log(result);

      // Show a toast message based on the response status
      if (result.statusCode === 200) {
        console.log(result);

        // setLoading(true);
        
        // Extract email domain
        const email = result.email_id; // Assuming result contains the email
        const domain = email.split('@')[1]; // Extract domain part from email
        switch (domain) {
          case 'gmail.com':
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your Gmail inbox.`,
            });
            setMessage(`${result.message}. Please check your Gmail inbox.`);
            break;
          case 'outlook.com':
          case 'office.com':
          case 'hotmail.com':
          case 'live.com':
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your Outlook inbox. If you don't see it, make sure to check the Junk or Spam folder.`,
            });
            setMessage(`${result.message}. Please check your Outlook inbox. If you don't see it, make sure to check the Junk or Spam folder.`);
            break;
          case 'yahoo.com':
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your Yahoo inbox. If it's not there, check the Spam folder.`,
            });
            break;
          case 'zoho.com':
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your Zoho inbox.`,
            });
            setMessage(`${result.message}. Please check your Zoho inbox.`);
            break;
          case 'icloud.com':
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your iCloud inbox. Be sure to check the Junk folder if necessary.`,
            });
            setMessage(`${result.message}. Please check your iCloud inbox. Be sure to check the Junk folder if necessary.`);
            break;
          case 'aol.com':
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your AOL inbox. Don't forget to check the Spam folder as well.`,
            });
            setMessage(`${result.message}. Please check your AOL inbox. Don't forget to check the Spam folder as well.`);
            break;
          default:
            setSuccessMessage({
              statusCode: 200,
              status: `${result.status}`,
              message: `${result.message}. Please check your inbox. If you don't find it, check your Junk or Spam folder.`,
            });
            setMessage(`${result.message}. Please check your inbox. If you don't find it, check your Junk or Spam folder.`);
            break;
        }
        
        setTimeout(() => {

          // Redirect based on email domain using switch
          switch (domain) {
            case 'gmail.com':
              window.location.href = "https://mail.google.com/mail/";
              break;
            case 'outlook.com':
            case 'office.com':
            case 'hotmail.com':
            case 'live.com':
              window.location.href = "https://outlook.office.com/mail/";
              break;
            case 'yahoo.com':
              window.location.href = "https://mail.yahoo.com/";
              break;
            case 'zoho.com':
              window.location.href = "https://mail.zoho.com/";
              break;
            case 'icloud.com':
              window.location.href = "https://www.icloud.com/mail/";
              break;
            case 'aol.com':
              window.location.href = "https://mail.aol.com/";
              break;
            case 'protonmail.com':
              window.location.href = "https://mail.protonmail.com/";
              break;
            case 'gmx.com':
            case 'gmx.net':
              window.location.href = "https://www.gmx.com/";
              break;
            default:
              console.warn("Unsupported email domain. Redirecting to default.");
              window.location.href = "https://outlook.office.com/mail/"; // Default URL
              break;
          }

        }, 5000); // Delay for 3 seconds
      } else {
        setSuccessMessage(result);
      }

      setShowToast(true);
    } catch (error: any) {
      // Handle errors like network issues or API failures
      setShowToast(true);
      setSuccessMessage({
        statusCode: 400,
        status: `${error.response.data.status}`,
        message: `${error.response.data.message}`,
      });
      setUserLoginInfo({
        ...userLoginInfo,
        email_id: "",
      });
      setErrors({
        ...errors,
        email_id: "",
      });
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="login">
        <div className="login-container p-4">
          {/* <FaArrowCircleLeft size={30} onClick={() => window.history.back()} /> */}

          {loading ? (
            <>
              <h5 className="fw-normal text-primary text-center my-4 pb-3">
                {" "}
              
                {/* {message} */}
                {" "}
                <Loading />
              </h5>
              
            </>
          ) : (
            <>
              <div className="my-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2em"
                  height="2em"
                  viewBox="0 0 24 24"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                >
                  <path
                    fill="black"
                    d="M10.928 21a2.978 2.978 0 0 1-2.121-.879L1.686 13l7.121-7.121c1.133-1.134 3.109-1.134 4.242 0c.566.564.879 1.317.879 2.119c0 .746-.27 1.451-.764 2.002H18c1.654 0 3 1.346 3 3s-1.346 3-3 3h-4.836c.493.549.764 1.252.764 1.998a2.977 2.977 0 0 1-.879 2.124a2.983 2.983 0 0 1-2.121.878m-6.414-8l5.707 5.707a1.023 1.023 0 0 0 1.414 0c.189-.189.293-.441.293-.708s-.104-.517-.291-.705L8.342 14H18a1.001 1.001 0 0 0 0-2H8.342l3.293-3.293a.996.996 0 0 0 .001-1.413a1.023 1.023 0 0 0-1.415-.001z"
                  />
                  <title>Back Login Page</title>
                </svg>
                <span className="fw-bold fs-4 ps-5">Reset Password</span>
              </div>
              <Form onSubmit={handleClick}>
                <Form.Group className="">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email"
                    style={{fontFamily: "Poppins", fontSize: "16px"}}
                    size="lg"
                    value={userLoginInfo?.email_id}
                    onChange={(e: any) =>
                      setUserLoginInfo({
                        ...userLoginInfo,
                        email_id: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                {errors.email_id ? (
                  <p className="text-danger">{errors.email_id}</p>
                ) : (
                  <br />
                )}
                <PrimaryBtn style={{ width: "100%" }} type="submit" title="">
                  Submit
                </PrimaryBtn>
              </Form>
            </>
          )}
          {showToast && (
            <Toast
              messages={successMessage}
              onClose={() => setShowToast(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmailAddress;
