import axios from "axios";
import * as crypto from "crypto-js";
import React, { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../common/PasswordInput";
import PrimaryBtn from "../../common/PrimaryBtn";
import Toast from "../../common/Toast";
import "../../Styles/Login.scss";
import { stateContext } from "../../utils/stateContext";
interface UserLoginInfo {
  email_id: string | null;
  password: string;
}

const Login: React.FC = () => {
  const [userLoginInfo, setUserLoginInfo] = useState<UserLoginInfo>({
    email_id: "",
    password: "",
  });
  const {
    state:{role_id},
    dispatch

  } = useContext(stateContext);
  
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forceLogout, setForceLogout] = useState(false);
  const [userId, setUserId] = useState("");
  const [role, setRole] = useState("");


  const handleClick = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowToast(true);
    try {
      console.log(userLoginInfo, "userLoginInfo");

      const userDataFromAPI = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users/${userLoginInfo?.email_id}`
      );
      console.log(userDataFromAPI, "userDataFromAPI");


      if (
        userDataFromAPI?.data?.length > 0 &&
        userDataFromAPI?.data[0]?.created_on
      ) {



        //Code below works for deployment

        
        const inputDateString = userDataFromAPI?.data[0]?.created_on;
        const utcDate = inputDateString.slice(0, 19).replace("T", " ");
        console.log(utcDate, "utcDate");

        const hash = crypto
          .SHA512(userLoginInfo?.password + utcDate)
          .toString();


        //Below code is for localhost backend code

        // const dateString = userDataFromAPI?.data[0]?.created_on;
        // console.log(dateString, "dateString");

        // const dateObject = new Date(dateString);
        // const formattedTime = `${dateObject.getFullYear()}-${String(
        //   dateObject.getMonth() + 1
        // ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(
        //   2,
        //   "0"
        // )} ${String(dateObject.getHours()).padStart(2, "0")}:${String(
        //   dateObject.getMinutes()
        // ).padStart(2, "0")}:${String(dateObject.getSeconds()).padStart(
        //   2,
        //   "0"
        // )}`;
      
        // const hash = crypto
        //   .SHA512(userLoginInfo?.password + formattedTime)
        //   .toString();
        // console.log(hash, "hash");
        
        const loginResponse = await axios.post(
          `/login`,
          {
            email_id: userLoginInfo?.email_id,
            passwd: hash,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }
        );
        // const loginResponse = await fetch(
        //   `${process.env.REACT_APP_BASE_URL}/login`,
        //   {
        //     method: "POST",
        //     body: JSON.stringify({
        //       email_id: userLoginInfo?.email_id,
        //       passwd: hash,
        //     }),
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     credentials: "include",
        //   }
        // )
        console.log(loginResponse, "loginResponse");


        if (loginResponse.status === 200) {
          const userData = loginResponse?.data;
          // const userData = await loginResponse.json();
          if (userData.status === "Success") {
            const localTime = new Date();
            const logintime = `${String(localTime.getHours()).padStart(
              2,
              "0"
            )}:${String(localTime.getMinutes()).padStart(2, "0")}:${String(
              localTime.getSeconds()
            ).padStart(2, "0")}`;

            const userDetails = {
              isLoggedIn: true,
              logTime: logintime,
              ...userData,
            };
          sessionStorage.setItem("userDetails", JSON.stringify(userDetails));

            const userDataFromSession = sessionStorage.getItem("userDetails")
              ? JSON.parse(sessionStorage.getItem("userDetails")!)
              : null;
            console.log(userDataFromSession, "userDataFromSession");

            if (userDataFromSession && userDataFromSession?.isLoggedIn) {
              console.log("Login successful");
              
              dispatch({ type: "LOGIN", payload: true });
              dispatch({
                type: "USER_DATA",
                payload: userDataFromSession,
              });
              dispatch({type: "TOKEN", payload: userData.accessToken})
              console.log("Login successful");

              navigate("/");
            }
          } else {
            alert("Invalid Credentials");
          }
        } else {
          console.log(`Error logging in. Status: ${loginResponse?.status}`);
        }
      } else {
        // setErrorMessage(userDataFromAPI?.data)
        // console.log("User not found", userDataFromAPI);
        // handleShowToast("Something went wrong", "Failure");
      }
    } catch (error: any) {
      // alert(error.message);
      if (error) {
        if (error?.message === "Network Error") {
          const netWorkError: any = {
            statusCode: 500,
            status: "Failure",
            message: error?.message,
          };
          setErrorMessage(netWorkError);
        } else {
          setErrorMessage(error?.response?.data);
          if (error?.response?.data?.statusCode === 422) {
            setForceLogout(true);
            setUserId(error?.response?.data?.user_id);
            setRole(error?.response?.data?.role_id);
          }
        }
      } else {
        // handleShowToast(error.response.data?.message, "Failure");
      }
    }
  };
  const toastRefresh = () => {
    setShowToast(false);
    setErrorMessage("");
  };

  const handleSubmit = async () => {
    try {
      // const inputDateString = new Date().toISOString();
      // const utcDate = inputDateString.slice(0, 19).replace("T", " ");
      // const logOutPayload = {
      //   logout_time: utcDate,
      // };
      // await updateLogOutTime(logOutPayload, userId);
      // await cookieRomove();
      // const inputDateString = new Date().toISOString();
      // const utcDate = inputDateString.slice(0, 19).replace("T", " ");
      // const loginResponse = await axios.put(
      //   `/users/logout/${userId}`,
      //   {
      //     logout_time: utcDate,

      //   },
      //   {
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //       role_id: role,
      //     },
      //     withCredentials: true,
      //   }
      // );
      const removecookies = await axios.patch(`/logout`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
          role_id: role,
        },
        withCredentials: true,
      });
      sessionStorage.clear();
      // cookies.remove("jwt");
      dispatch({
        type: "LOGOUT",
      });
      setErrorMessage("");
      setForceLogout(false);
      setShowToast(false);
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="login">
      <div className="login-container p-5">
        <div className="d-flex justify-content-center mt-2">
          {" "}
          {/* Centering the Logo */}
          <span className="h2 fw-bold mb-0">Login</span>
        </div>
        <h5
          className="fw-normal text-center my-4 pb-3"
          style={{ letterSpacing: "1px" }}
        >
          Sign into your account
        </h5>
        <Form
          onSubmit={handleClick}
        >
          <Form.Group className="mb-4">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your email"
              style={{fontFamily: "Poppins", fontSize: "16px"}}
              size="lg"
              onChange={(e: any) =>
                setUserLoginInfo({
                  ...userLoginInfo,
                  email_id: e.target.value,
                })
              }
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>Password</Form.Label>
            <PasswordInput
              onChange={(e: any) =>
                setUserLoginInfo({
                  ...userLoginInfo,
                  password: e.target.value,
                })
              }
            />
          </Form.Group>
          {/* forgot password and create account is added in this code */}
          <div className="d-flex justify-content-end align-items-center mb-4">
            {/* <div>
              <Form.Check
                type="checkbox"
                id="customControlInline"
                label="Remember me"
              />
            </div> */}
            <div>
            <span
               onClick={() => navigate("/EmailAddress")}
              style={{ color: "var(--Colours-Primary-colour-Blue-500)", textDecoration: "underline", cursor: "pointer" }}
            >
              Forgot password?
            </span>
            </div>
          </div>
          <PrimaryBtn style={{ width: "100%" }} type="submit" title="">
            Login
          </PrimaryBtn>
          {showToast && (
            <Toast messages={errorMessage} onClose={() => toastRefresh()} />
          )}
        </Form>
        {/* registeration */}
        <div className="text-center mt-3">
          <p className="mb-0">
            Don't have an account?{" "}
            <span
               onClick={() => navigate("/Register")}
              style={{ color: "var(--Colours-Primary-colour-Blue-500)", textDecoration: "underline", cursor: "pointer" }}
            >
              Register
            </span>
          </p>
        </div>
      </div>
      <>
        {forceLogout && (
          <div className="force-popup">
            <div className="Popup">
              <h3 className="mb-4">Logout</h3>
              <h6>Are you sure you want to logout?</h6>
              <div className="text-end mt-5">
                <PrimaryBtn
                  onClick={() => setForceLogout(false)}
                  style={{
                    background: "none",
                    color: "var(--Colours-Primary-colour-Blue-500)",
                    border: "1px solid var(--Colours-Primary-colour-Blue-500)",
                  }}
                  title=""
                >
                  Cancel
                </PrimaryBtn>
                <PrimaryBtn
                  style={{ marginLeft: "1rem" }}
                  // onClick={handleSubmit}
                  title=""
                >
                  Yes
                </PrimaryBtn>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Login;
