// import { axiosPrivate } from "../api/axios";
// import { useEffect } from "react";
// import useRefreshToken from "./useRefreshToken";
// import { stateContext } from "./stateContext";
// import { useContext } from "react";

// const useAxiosPrivate = () => {
//     const {
//         state: {
//           token,
//           user_Data: { user_name, role_id, user_id },
//         },
//         dispatch,
//       } = useContext(stateContext);
//     //const token = JSON.parse(sessionStorage.getItem("userDetails"))?.token;

//       console.log("token", token)
//     const refresh = useRefreshToken();

//     useEffect(() => {
//         const requestIntercept = axiosPrivate.interceptors.request.use(
//             config => {
//                 if(!config.headers['Authorization']) //This means this is a first time request
//                 {
//                     console.log("token", token)
//                     config.headers['Authorization'] =  `Bearer ${token}`;
//                 }
//                 return config;
//             }, (error) => Promise.reject(error)
//         )
//         const responseIntercept = axiosPrivate.interceptors.response.use(
//             response => response,
//             //If accessToken is expired then handle the error below
//             async(error) => {
//                 console.log("handling error")
//                 const prevRequest = error?.config;
//                 if(error?.response?.status === 403 && !prevRequest?.sent) {
//                     console.log("403 error")
//                     prevRequest.sent == true; //this is done so that the above condition does not end in endless looping if 403 error comes again and again if refreshtoken is expired
//                     const newAccessToken = await refresh();
//                     prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
//                     return axiosPrivate(prevRequest);
//                 }
//                 return Promise.reject(error);
//             }
//         )

//         return () => {
//             axiosPrivate.interceptors.request.eject(requestIntercept);
//             axiosPrivate.interceptors.response.eject(responseIntercept);
//         }
//     },[refresh])
//     return axiosPrivate;
// }

// export default useAxiosPrivate;

import cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // For navigation
import { axiosPrivate } from "../api/axios";
import { stateContext } from "./stateContext";
import useRefreshToken from "./useRefreshToken";
import { convertUtcToIst, formatUtcDate } from "./CustomFunctions";

const useAxiosPrivate = () => {
  const {
    state: {
      token,
      user_Data: { user_name, role_id, user_id },
    },
    dispatch,
  } = useContext(stateContext);

  const inputDateString = new Date().toISOString();
        const utcDate = convertUtcToIst(inputDateString);

  const refresh = useRefreshToken();
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        config.headers["role_id"] = role_id;
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.log("handling error");
        const prevRequest = error?.config;

        if (error?.response?.status === 403 && !prevRequest?.sent) {
          console.log("403 error");
          prevRequest.sent = true;

          try {
            const newAccessToken = await refresh();
            //Add new accesstoken to headers
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
            // Re-add role_id to headers for the retried request
            prevRequest.headers["role_id"] = role_id;
            return axiosPrivate(prevRequest);
          } catch (refreshError) {
            // /logout api call in this code block
            // const loginResponse = await axiosPrivate.patch(
            console.log(utcDate)
              const loginResponse = await axiosPrivate.put(
                `/users/logout/${user_id}`,
                {
                  logout_time: utcDate,
                }
            );
            await axiosPrivate.patch(`${process.env.REACT_APP_BASE_URL}/logout`);
            if (loginResponse.status === 200) {
                sessionStorage.clear();
                cookies.remove("jwt");
                dispatch({
                  type: "LOGOUT",
                });
                navigate("/");
              }
            return Promise.reject(refreshError);
          }
        } else if (error?.response?.status === 500) {
          dispatch({ type: "NETWORK_ERROR", payload: true });
          console.error("Internal Server Error: ", error);
          return Promise.reject(error);
        } else if (error?.response?.status === 406) {
          console.log(utcDate)
          // this axios private based api call for /logout api
          await axiosPrivate.patch(`${process.env.REACT_APP_BASE_URL}/logout`);
            console.log("line 147")
            sessionStorage.clear();
            cookies.remove("jwt");
            dispatch({
              type: "LOGOUT",
            });
            navigate("/");

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [refresh, token, navigate, dispatch]);

  return axiosPrivate;
};

export default useAxiosPrivate;
