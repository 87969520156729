const userDataFromSession = JSON.parse(sessionStorage.getItem("userDetails"));
console.log(userDataFromSession, "userDataFromSession");
const popupData = JSON.parse(sessionStorage.getItem("Popup"));

export const inializeState = {
  isLogin: userDataFromSession?.isLoggedIn || false,
  token: userDataFromSession && userDataFromSession?.token,
  user_Data: userDataFromSession && userDataFromSession,
  networkError: false,
  popupData: popupData && popupData,
  user: null,
  chat: false,
  resetPassword:null,
  logsView:false
};

export const StateReducer = (state, action) => {
  console.log(action, "action");
  switch (action.type) {
    
    case "LOGIN":
      return { ...state, isLogin: action.payload };
    case "TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "USER_DATA":
      return {
        ...state,
        user_Data: action.payload,
      };
    case "NEW_CHAT":
      return {
        ...state,
        chat: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        isLogin: false,
      };
      case "NETWORK_ERROR":
        return {
          ...state,
          networkError: action.payload,
        };
        case "POPUP":
          return {
            ...state,
            popupData: action.payload,
          };
          case "LOGS_VIEW":
            return {
              ...state,
              logsView: action.payload,
            };
            case "RESET_PASSWORD":
              return {
                ...state,
                resetPassword: action.payload,
              } 
    default:
      return state;
  }
};
