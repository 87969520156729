import React, { useState, useEffect } from 'react';
import useAxiosPrivate from '../../utils/useAxiosPrivate';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

type User = {
  user_id: number;
  user_name: string;
  role_id: number;
  email_id: string;
};

const downloadFile = async (
  selectedUser: string,
  selectedMonth: number,
  selectedYear: string,
  userName: string,
  axiosPrivate: any // Accept axiosPrivate as a parameter
) => {
  console.log(`Downloading file for User: ${selectedUser}, Month: (${selectedMonth} + 1), Year: ${selectedYear}`);
  
  try {
    // Get thread id of user
    const rows = await axiosPrivate.post(`${process.env.REACT_APP_BASE_URL}/threadmsg/newThread`, {
      userId: selectedUser,
    });
    
    if (rows.status === 200) {
      const thread_id = rows.data.data;
      const response = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URL}/threadmsg/download?user_id=${selectedUser}&thread_id=${thread_id}&month=${selectedMonth + 1}&year=${selectedYear}`);
      console.log(response.data)
      if (response.data.length !== 0) {
      // Define the headers for the new sheet
      const headers = ["Request", "Response", "Created On"];

      // Create a new workbook
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(userName); // Set the sheet name as user name

      // Add the header row
      const headerRow = worksheet.addRow(headers);

      // Apply bold style to the header row
      headerRow.eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { wrapText: true };
      });

      // Add the data rows
      response.data.forEach((item: { request: string; response: string; created_on: string }) => {
        const rowValues = [item.request, item.response, new Date(item.created_on).toLocaleString()];
        worksheet.addRow(rowValues);
      });

      // Set column widths and wrap text
      worksheet.columns.forEach((column) => {
        column.width = 30; // Set a fixed column width
        column.alignment = { wrapText: true };
      });

      // Generate the Excel file
      const buffer = await workbook.xlsx.writeBuffer();

      // Save the file using file-saver
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${userName}_logs.xlsx`);
    } else {
      alert('No logs for the selected user for the given month and year. Please choose another month and year');
    }
  }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const Download = () => {
  const axiosPrivate = useAxiosPrivate(); // Call useAxiosPrivate hook in the component

  const [users, setUsers] = useState<User[]>([]); // User list from API
  const [selectedUser, setSelectedUser] = useState<string>(''); // Selected user
  console.log(selectedUser, "selectedUser");
  
  const [selectedMonth, setSelectedMonth] = useState<string>(''); // Selected month
  const [selectedYear, setSelectedYear] = useState<string>('2024'); // Default year

  // Fetch user list from API on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosPrivate.get(`${process.env.REACT_APP_BASE_URL}/users`); // Replace with actual API endpoint
        const data = response.data;
        setUsers(data); // Assuming data is an array of user names
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [axiosPrivate]);

  // Months array
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Years array
  const years = ['2024', '2025', '2026'];

  const handleDownload = () => {
    if (selectedUser && selectedMonth && selectedYear) {
      const user = users.find(u => u.user_id === Number(selectedUser));
      const userName = user?.user_name;
      if (userName) {
        // Pass axiosPrivate to the downloadFile function
        downloadFile(selectedUser, Number(selectedMonth), selectedYear, userName, axiosPrivate);
      }
    } else {
      alert('Please select a user, month, and year.');
    }
  };

  return (
    <div>
      <h3>Select User, Month, and Year</h3>

      {/* User Dropdown */}
      <div>
        <label htmlFor="user">User:</label>
        <select id="user" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
          <option value="">Select User</option>
          {users.map((user, index) => (
            <option key={user.user_id} value={user.user_id}>
              {user.user_name}
            </option>
          ))}
        </select>
      </div>

      {/* Month Dropdown */}
      <div>
        <label htmlFor="month">Month:</label>
        <select id="month" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
          <option value="">Select Month</option>
          {months.map((month, index) => (
            <option key={index} value={index}>
              {month}
            </option>
          ))}
        </select>
      </div>

      {/* Year Dropdown */}
      <div>
        <label htmlFor="year">Year:</label>
        <select id="year" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
          {years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>

      {/* Download Button */}
      <button onClick={handleDownload}>Download</button>
    </div>
  );
};

export default Download;
