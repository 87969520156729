// import React, { useContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Container, Row, Col, Form } from "react-bootstrap";
// import * as crypto from "crypto-js";
// import Toast from "../../common/Toast";
// import "../../Styles/Registration.scss";
// import PasswordInput from "../../common/PasswordInput";
// import { stateContext } from "../../utils/stateContext";
// import PrimaryBtn from "../../common/PrimaryBtn";
// // import cookies from "js-cookie";
// interface UsersignupInfo {
//   email_id: string | null;
//   password: string;
// }

// const Registration: React.FC = () => {
//   const [usersignupInfo, setUsersignupInfo] = useState<UsersignupInfo>({
//     email_id: "",
//     password: "",
//   });
//   const {
//     state,
//     dispatch

//   } = useContext(stateContext);
//   const navigate = useNavigate();
//   const [showToast, setShowToast] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");
//   const [forceLogout, setForceLogout] = useState(false);
//   const [userId, setUserId] = useState("");
//   const [role, setRole] = useState("");

//   const handleClick = async (e: React.FormEvent) => {
//     e.preventDefault();
//     setShowToast(true);
//     try {
//       const userDataFromAPI = await axios.get(
//         `/users/${usersignupInfo?.email_id}`
//       );

//       if (
//         userDataFromAPI?.data?.length > 0 &&
//         userDataFromAPI?.data[0]?.created_on
//       ) {
//         // const inputDateString = userDataFromAPI?.data[0]?.created_on;
//         // const utcDate = inputDateString.slice(0, 19).replace("T", " ");
        // console.log(utcDate, "utcDate");

//         // const hash = crypto
//         //   .SHA512(usersignupInfo?.password + utcDate)
//         //   .toString();

//         const dateString = userDataFromAPI?.data[0]?.created_on;
//         const dateObject = new Date(dateString);
//         const formattedTime = `${dateObject.getFullYear()}-${String(
//           dateObject.getMonth() + 1
//         ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(
//           2,
//           "0"
//         )} ${String(dateObject.getHours()).padStart(2, "0")}:${String(
//           dateObject.getMinutes()
//         ).padStart(2, "0")}:${String(dateObject.getSeconds()).padStart(
//           2,
//           "0"
//         )}`;
//         const hash = crypto
//           .SHA512(usersignupInfo?.password + formattedTime)
//           .toString();

//         const signupResponse = await axios.post(
//           `/signup`,
//           {
//             email_id: usersignupInfo?.email_id,
//             passwd: hash,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//             },
//             withCredentials: true,
//           }
//         );

//         if (signupResponse.status === 200) {
//           const userData = signupResponse?.data;
//           if (userData.status === "Success") {
//             const localTime = new Date();
//             const signuptime = `${String(localTime.getHours()).padStart(
//               2,
//               "0"
//             )}:${String(localTime.getMinutes()).padStart(2, "0")}:${String(
//               localTime.getSeconds()
//             ).padStart(2, "0")}`;

//             const userDetails = {
//               isLoggedIn: true,
//               logTime: signuptime,
//               ...userData,
//             };
//             sessionStorage.setItem("userDetails", JSON.stringify(userDetails));

//             const userDataFromSession = sessionStorage.getItem("userDetails")
//               ? JSON.parse(sessionStorage.getItem("userDetails")!)
//               : null;
//             console.log(userDataFromSession, "userDataFromSession");

//             if (userDataFromSession && userDataFromSession?.isLoggedIn) {
//               dispatch({ type: "signup", payload: true });
//               dispatch({
//                 type: "USER_DATA",
//                 payload: userDataFromSession,
//               });
//               navigate("/");
//             }
//           } else {
//             alert("Invalid Credentials");
//           }
//         } else {
//           console.log(`Error logging in. Status: ${signupResponse?.status}`);
//         }
//       } else {
//         // setErrorMessage(userDataFromAPI?.data)
//         // console.log("User not found", userDataFromAPI);
//         // handleShowToast("Something went wrong", "Failure");
//       }
//     } catch (error: any) {
//       if (error) {
//         if (error?.message === "Network Error") {
//           const netWorkError: any = {
//             statusCode: 500,
//             status: "Failure",
//             message: error?.message,
//           };
//           setErrorMessage(netWorkError);
//         } else {
//           setErrorMessage(error?.response?.data);
//           if (error?.response?.data?.statusCode === 422) {
//             setForceLogout(true);
//             setUserId(error?.response?.data?.user_id);
//             setRole(error?.response?.data?.role_id);
//           }
//         }
//       } else {
//         //   handleShowToast(error.response.data?.message, "Failure");
//       }
//     }
//   };
//   const toastRefresh = () => {
//     setShowToast(false);
//     setErrorMessage("");
//   };

//   const handleSubmit = async () => {
//     try {
//       // const inputDateString = new Date().toISOString();
//       // const utcDate = inputDateString.slice(0, 19).replace("T", " ");
//       // const logOutPayload = {
//       //   logout_time: utcDate,
//       // };
//       // await updateLogOutTime(logOutPayload, userId);
//       // await cookieRomove();
//       // const inputDateString = new Date().toISOString();
//       // const utcDate = inputDateString.slice(0, 19).replace("T", " ");
//       // const signupResponse = await axios.put(
//       //   `/users/logout/${userId}`,
//       //   {
//       //     logout_time: utcDate,

//       //   },
//       //   {
//       //     headers: {
//       //       "Content-Type": "application/json",
//       //       Authorization: `Bearer ${token}`,
//       //       role_id: role,
//       //     },
//       //     withCredentials: true,
//       //   }
//       // );
//       const removecookies = await axios.patch(`/logout`, {
//         headers: {
//           "Content-Type": "application/json",
//           // Authorization: `Bearer ${token}`,
//           role_id: role,
//         },
//         withCredentials: true,
//       });
//       sessionStorage.clear();
//       // cookies.remove("jwt");
//       dispatch({
//         type: "LOGOUT",
//       });
//       setErrorMessage("");
//       setForceLogout(false);
//       setShowToast(false);
//       navigate("/");
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   return (
//     <div className="signup">
//       <div className="signup-container p-5">
//         <div className="d-flex justify-content-center mt-2">
//           {" "}
//           {/* Centering the Logo */}
//           <span className="h2 fw-bold mb-0">Sign Up</span>
//         </div>
//         <h5
//           className="fw-normal text-center my-4 pb-3"
//           style={{ letterSpacing: "1px" }}
//         >
//          Create your account
//         </h5>
//         <Form onSubmit={handleClick}>
//           <Form.Group className="mb-4">
//             <Form.Label>Email address</Form.Label>
//             <Form.Control
//               type="email"
//               placeholder="Enter email"
//               size="lg"
//               onChange={(e: any) =>
//                 setUsersignupInfo({
//                   ...usersignupInfo,
//                   email_id: e.target.value,
//                 })
//               }
//             />
//           </Form.Group>
//           <Form.Group className="mb-4">
//             <Form.Label>Password</Form.Label>
//             <PasswordInput
//               onChange={(e: any) =>
//                 setUsersignupInfo({
//                   ...usersignupInfo,
//                   password: e.target.value,
//                 })
//               }
//             />
//           </Form.Group>
//           {/* forgot password and create account is added in this code */}
//           <div className="d-flex justify-content-between align-items-center mb-4">
//             <div>
//               <Form.Check
//                 type="checkbox"
//                 id="customControlInline"
//                 label="Remember me"
//               />
//             </div>
//             <div>
//               <a
//                 href="#"
//                 style={{ color: "var(--Colours-Primary-colour-Blue-500)" }}
//               >
//                 Forgot password?
//               </a>
//             </div>
//           </div>
//           <PrimaryBtn style={{ width: "100%" }} type="submit" title="">
//             signup
//           </PrimaryBtn>
//           {showToast && (
//             <Toast messages={errorMessage} onClose={() => toastRefresh()} />
//           )}
//         </Form>
//         {/* registeration */}
//         <div className="text-center mt-3">
//           <p className="mb-0">
//             Don't have an account?{" "}
//             <a
//               href="#"
//               style={{ color: "var(--Colours-Primary-colour-Blue-500)" }}
//             >
//               Register
//             </a>
//           </p>
//         </div>
//       </div>
//       <>
//         {forceLogout && (
//           <div className="force-popup">
//             <div className="Popup">
//               <h3 className="mb-4">Logout</h3>
//               <h6>Are you sure you want to logout?</h6>
//               <div className="text-end mt-5">
//                 <PrimaryBtn
//                   onClick={() => setForceLogout(false)}
//                   style={{
//                     background: "none",
//                     color: "var(--Colours-Primary-colour-Blue-500)",
//                     border: "1px solid var(--Colours-Primary-colour-Blue-500)",
//                   }}
//                   title=""
//                 >
//                   Cancel
//                 </PrimaryBtn>
//                 <PrimaryBtn
//                   style={{ marginLeft: "1rem" }}
//                   onClick={handleSubmit}
//                   title=""
//                 >
//                   Yes
//                 </PrimaryBtn>
//               </div>
//             </div>
//           </div>
//         )}
//       </>
//     </div>
//   );
// };

// export default Registration;

import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import "../../Styles/Registration.scss";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../common/PrimaryBtn";
import PasswordInput from "../../common/PasswordInput";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { stateContext } from "../../utils/stateContext";
import UseFetch from "../../utils/useFetch";
import Toast from "../../common/Toast";
import Loading from "../../common/Loading";
import crypto from "crypto-js";


const Registration = () => {

  const {
    state, dispatch
  } = useContext(stateContext);



  const [usersignupInfo, setUsersignupInfo] = useState<any>({
    userName: "",
    email_id: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  });
  // console.log(usersignupInfo);

  const navigate = useNavigate();
  const { apiCall: modelInsertUser,message} = UseFetch("/loginInsert", "POST", dispatch);
  // console.log(message, "message in registration");
 const insertMessage:any = message;
//  console.log(insertMessage, "insertMessage in registration");
 

 // const message =
 //   {
 //     statusCode: 200,
 //     status: 'Success',
 //     message: 'User registration successful'
   
 // }

 // const message = null;

 useEffect(() => {
   if (insertMessage && insertMessage.statusCode == 200) {
     setShowToast(true);
     setLoading(true);
     setTimeout(() => {
       setShowToast(false);
       navigate("/");
     }, 2000);
   }
   else {
     setShowToast(true);
     setTimeout(() => {
       setShowToast(false);
     }, 2000); 
     setUsersignupInfo({
       userName: "",
       email_id: "",
       password: "",
       confirmPassword: "",
       phoneNumber: "",
     })
   }
 }, [message]);

  const [errors, setErrors] = useState<any>("")
  // console.log(errors);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClick = (event: any) => {
    event.preventDefault();


    const requiredFields: { [key: string]: string } = {
      userName: "Please enter your name",
      email_id: "Please enter your email",
      password: "Please enter your password",
      confirmPassword: "Please enter your confirm password",
      // phoneNumber: "Please enter your phone number"
    };

    const newErrors: { [key: string]: string } = {};
    let isValid = true;

    for (const field in requiredFields) {
      if (!usersignupInfo[field]) {
        newErrors[field] = requiredFields[field];
        isValid = false;
      }
    }
    // console.log(usersignupInfo.password, usersignupInfo.confirmPassword);

    if (usersignupInfo.password != usersignupInfo.confirmPassword) {
      // console.log(usersignupInfo.password, usersignupInfo.confirmPassword);

      newErrors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    setErrors(newErrors);
    // console.log(insertMessage, "insertMessage in registration");
    

    if (isValid) {
      const inputDateString = new Date().toISOString();
      const utcDate = inputDateString.slice(0, 19).replace("T", " ");
      try {
         const hash = crypto.SHA512(usersignupInfo?.password + utcDate)
        //  console.log(hash.toString(), "hash");
        //  console.log(utcDate, "utcDate");
         
         
        
        // Add your submit logic here
        const insertUser = {
          user_name: usersignupInfo.userName,
          passwd: hash.toString(),
          email_id: usersignupInfo.email_id,
          phone_num: usersignupInfo.phoneNumber ? usersignupInfo.phoneNumber : null,
          created_on: utcDate,
        }
        modelInsertUser(insertUser);

      }
      catch (error) {
        // console.log(error);
      }
    }
  };


  return (
    <div className="signup">
      <div className="signup-container">
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2em"
            height="2em"
            viewBox="0 0 24 24"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            onClick={() => navigate("/")}
          >
            <path
              fill="black"
              d="M10.928 21a2.978 2.978 0 0 1-2.121-.879L1.686 13l7.121-7.121c1.133-1.134 3.109-1.134 4.242 0c.566.564.879 1.317.879 2.119c0 .746-.27 1.451-.764 2.002H18c1.654 0 3 1.346 3 3s-1.346 3-3 3h-4.836c.493.549.764 1.252.764 1.998a2.977 2.977 0 0 1-.879 2.124a2.983 2.983 0 0 1-2.121.878m-6.414-8l5.707 5.707a1.023 1.023 0 0 0 1.414 0c.189-.189.293-.441.293-.708s-.104-.517-.291-.705L8.342 14H18a1.001 1.001 0 0 0 0-2H8.342l3.293-3.293a.996.996 0 0 0 .001-1.413a1.023 1.023 0 0 0-1.415-.001z"
            />
            <title>Back Login Page</title>
          </svg>
        </span>
        <div className="text-center my-2">
          <span className="h2 fw-bold mb-0">Sign Up</span>
        </div>
        {/* <h5
          className="fw-normal text-center my-3 pb-2"
          style={{ letterSpacing: "1px" }}
        >
          Create your account
        </h5> */}
        <Form onSubmit={handleClick}>
          <Form.Group className="mb-2">
            <Form.Label>User Name<span className="text-danger m-2">*</span></Form.Label>
            <Form.Control
              type="text"
              value={usersignupInfo.userName}
              placeholder="User name"
              style={{fontFamily: "Poppins", fontSize: "16px"}}
              size="lg"
              onChange={(e: any) =>
                setUsersignupInfo({
                  ...usersignupInfo,
                  userName: e.target.value,
                })
              }
            />
            {errors.userName && <p className="text-danger">{errors.userName}</p>}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Email address<span className="text-danger m-2">*</span></Form.Label>
            <Form.Control
              type="email"
              value={usersignupInfo.email_id}
              placeholder="Enter email"
              style={{fontFamily: "Poppins", fontSize: "16px"}}
              size="lg"
              onChange={(e: any) =>
                setUsersignupInfo({
                  ...usersignupInfo,
                  email_id: e.target.value,
                })
              }
            />
            {errors.email_id && <p className="text-danger">{errors.email_id}</p>}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Password<span className="text-danger m-2">*</span></Form.Label>
            <PasswordInput
              // type="password"
              // placeholder="Enter password"
              // size="lg"
              value={usersignupInfo.password}
              onChange={(e: any) =>
                setUsersignupInfo({
                  ...usersignupInfo,
                  password: e.target.value,
                })
              }
            />
            {errors.password && <p className="text-danger">{errors.password}</p>}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Confirm Password<span className="text-danger m-2">*</span></Form.Label>
            <PasswordInput
              // type="password"
              // placeholder="Confirm password"
              // size="lg"
              value={usersignupInfo.confirmPassword}
              onChange={(e: any) =>
                setUsersignupInfo({
                  ...usersignupInfo,
                  confirmPassword: e.target.value,
                })
              }
            />
            {errors.confirmPassword && <p className="text-danger">{errors.confirmPassword}</p>}
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Phone Number</Form.Label>
            <PhoneInput
              country={"in"}
              value={usersignupInfo.phoneNumber}
              onChange={(phone: string) =>
                setUsersignupInfo({
                  ...usersignupInfo,
                  phoneNumber: phone,
                })
              }
            />
            {errors.phoneNumber && <p className="text-danger">{errors.phoneNumber}</p>}
          </Form.Group>
          <div className="d-flex justify-content-center">
            <PrimaryBtn type="submit" title="">
              Create Account
            </PrimaryBtn>
          </div>
          {loading && <Loading />}
          {showToast && (<Toast messages={message} onClose={() => setShowToast(false)} />)}
        </Form>
      </div>
    </div>
  );
};

export default Registration;
