import React, { useContext, useEffect, useState } from "react";
import "../Styles/Popup.scss";
import { stateContext } from "../utils/stateContext";
import Logout from "./Logout";

interface ErrorMessage {
  user_name?: string;
  company_name?: string;
  email_id?: string;
  password?: string;
  phone_number?: string;
}
const Popup = () => {
  const {
    state: {
      popupData: { user, type, solution_id, version_solution_id },
      user_Data: { user_id, role_id },
    },
    dispatch,
  } = useContext(stateContext);
  const [showToast, setShowToast] = useState(false);




  const close = () => {
    sessionStorage.removeItem("Popup");
    dispatch({
      type: "POPUP",
      payload: {
        showPopup: false,
      },
    });
    // setRefetch(true);
    setShowToast(false);
  };
  const renderPopupContent = () => {
    switch (type) {
      case "Logout":
        return (
          <>
            <div className="Popup-container">
              <div className="Popup1">
                <Logout close={close} user_id={user_id} dispatch={dispatch} />
              </div>
            </div>
          </>
        );

      default:
        return <div></div>;
    }
  };

  return <>{renderPopupContent()}</>;
};

export default Popup;
