import React, { useContext, useEffect, useRef, useState } from "react";
import { FaBolt, FaGreaterThan, FaLessThan } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import CloseToast from "../../common/CloseToast";
import Toast from "../../common/Toast";
import TypingText from "../../common/TypingText";
import "../../Styles/Chatbot.scss";
import { convertUtcToIst, formatUtcDate } from "../../utils/CustomFunctions";
import { stateContext } from "../../utils/stateContext";
import useAxiosPrivate from "../../utils/useAxiosPrivate";

interface PredefinedQuestions {
  pre_question_id: number;
  pre_questions: string;
  created_on: string;
}
interface AlLPredefinedQuestions {
  pre_question_id: number;
  pre_questions: string;
  created_on: string;
}

interface PredefinedQuestion {
  pre_question_id: number;
  pre_questions: string;
  created_on: string;
}

type QuestionType = PredefinedQuestion | string;

const Chatbox = () => {
  const {
    state: {
      chat,
      token,
      navigation_path,
      user_Data: { user_name, role_id, user_id },
    },
    dispatch,
  } = useContext(stateContext);
  // console.log(user_id, user_name, token, role_id);
  console.log(navigation_path, "navigation_path");

  const axiosPrivate = useAxiosPrivate();
  const location = useLocation(); // Get the current location
  // console.log(location, "location");

  // get pre-defined questions api call
  useEffect(() => {
    const fetchPredefinedQuestions = async () => {
      try {
        const response = await axiosPrivate.get(`/question/pre_questions`);
        setAllPredefine(response.data);
      } catch (error) {
        console.error("Error fetching predefined questions:", error);
      }
    };

    fetchPredefinedQuestions();
  }, [token, role_id]);

  const [allPredefine, setAllPredefine] = useState<AlLPredefinedQuestions[]>(
    []
  );
  console.log(allPredefine);

  const [message, setMessage] = React.useState("");

  const [ThreadId, setThreadId] = useState<number>(0);
  // console.log(ThreadId)

  useEffect(() => {
    const createThreadIfNeeded = async () => {
      // const inputDateString = new Date().toISOString();

      // Call the API to send the message if threadId is 0

      try {
        const inputDateString = new Date().toISOString();
        const utcDate = convertUtcToIst(
          inputDateString.slice(0, 19).replace("T", " ")
        );
        // console.log(utcDate, "utcDate");
        const response = await axiosPrivate.post(
          `${process.env.REACT_APP_BASE_URL}/threadmsg/newThread`,
          {
            userId: user_id,
            created_on: utcDate,
            thread_name: "untitled",
          }
        );

        if (response.status == 200) {
          setThreadId(response.data.data);
          // console.log(response)
          // console.log(response.data.data)
          // console.log(ThreadId)
        } else {
          console.error("Failed to create thread", response.statusText);
        }
      } catch (error) {
        console.error("Error creating thread:", error);
      }
    };

    createThreadIfNeeded();
  }, [token, user_id, role_id]); // Add dependencies to avoid unnecessary re-renders

  // Manage the display of questions
  const [remainingQuestions, setRemainingQuestions] = useState<
    PredefinedQuestions[]
  >([]);
  const [currentQuestions, setCurrentQuestions] = useState<
    PredefinedQuestions[]
  >([]);

  const [answeredQuestions, setAnsweredQuestions] = useState<
    PredefinedQuestions[]
  >([]);
  console.log(answeredQuestions, "answeredQuestions");
  const [clickedQuestions, setClickedQuestions] = useState<
    PredefinedQuestions[]
  >([]);
  const [responseAnswer, setResponseAnswer] = useState<any>([]);
  // console.log(responseAnswer, "responseAnswer");
  const [responseQuestionTiming, setResponseQuestionTiming] = useState<any>("");
  const [responseAnswerTiming, setResponseAnswerTiming] = useState<any>("");
  // console.log(responseAnswerTiming, "responseAnswerTiming");

  // console.log(answeredQuestions.length == 0 ? true : false);

  const [isTypingComplete, setIsTypingComplete] = useState(
    answeredQuestions.length == 0 ? true : false
  );
  // console.log(isTypingComplete);
  const [showNewChat, setShowNewChat] = useState(true);

  const [currentAnswerTyped, setCurrentAnswerTyped] = useState<boolean>(false);

  // useEffect(() => {
  const fetchSessionID = async () => {
    // console.log("timting issue");

    try {
      // console.log(ThreadId)
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BASE_URL}/threadmsg/${user_id}/${
          ThreadId ? ThreadId : sessionStorage.getItem("messageThreadId")
        }`
      );
      if (response.status == 200) {
        setMessages(response.data);
        convertRequestRespnse(response.data);
      }
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    if (ThreadId) {
      fetchSessionID();
    }
  }, [ThreadId]);

  const [messages, setMessages] = useState<any>([]);

  // Function to transform each request object
  function transformRequestArray(arr: any) {
    // console.log(arr, "arr");

    return arr.map((obj: any) => {
      if (obj.message_type === "request") {
        return {
          pre_questions: obj.message_content,
          created_on: obj.created_on,
        };
      } else {
        return {
          pre_answers: obj.message_content,
          created_on: obj.created_on,
        };
      }
    });
  }

  const convertRequestRespnse = (messages: any) => {
    // console.log(messages, "messages");

    const requests: any = [];
    const responses: any = [];
    messages.forEach((message: any) => {
      if (message.message_type === "request") {
        requests.push(message);
      } else if (message.message_type === "response") {
        responses.push(message);
      }
    });
    const requestResponse = transformRequestArray(requests);
    // console.log(requestResponse, "requestResponse");

    setAnsweredQuestions(requestResponse);
    setResponseAnswer(transformRequestArray(responses));
    // console.log(transformRequestArray(responses));
    // console.log(requestResponse, "requestResponse");
    responses.forEach((response: any) => {
      // console.log(response.created_on, "response");
      const newTiming = formatUtcDate(response.created_on);
      // console.log(newTiming, "newTiming");

      setResponseAnswerTiming((prevResponseAnswerTiming: any) => {
        // Check if newTiming is already in the array
        if (prevResponseAnswerTiming.includes(newTiming)) {
          return prevResponseAnswerTiming;
        }
        // Append the new timing if not already present
        return [...prevResponseAnswerTiming, newTiming];
      });
    });
    requests.forEach((response: any) => {
      const newTiming = formatUtcDate(response.created_on);
      setResponseQuestionTiming((prevResponseQuestionTiming: any) => {
        // Check if newTiming is already in the array
        if (prevResponseQuestionTiming.includes(newTiming)) {
          return prevResponseQuestionTiming;
        }

        // Append the new timing if not already present
        return [...prevResponseQuestionTiming, newTiming];
      });
    });
  };

  useEffect(() => {
    if (chat == true) {
      setAnsweredQuestions([]);
      setClickedQuestions([]);
      // setResponseAnswer([]);
      setShowNewChat(false);
      // setIsTypingComplete(true);
    }
  }, [chat]);

  const refreshNewChat = () => {
    dispatch({ type: "NEW_CHAT", payload: false });
  };

  const [showIntro, setShowIntro] = useState<boolean>(true);
  const [showClickQuestion, setShowClickQuestion] = useState<boolean>(false);
  const [showFinalMessage, setShowFinalMessage] = useState<boolean>(false);
  const [showPythonErrorMessage, setShowPythonErrorMessage] =
    useState<boolean>(false);
  const [availableQuestions, setAvailableQuestions] = useState<
    AlLPredefinedQuestions[]
  >([]);
  const [overture, setOverture] = useState<boolean>(false);

  const showFinalMessageToast = {
    statusCode: 200,
    status: "Success",
    message: "All Predefined Questions solved. Please type the custom messages",
  };
  const showPythonErrorlMessageToast = {
    statusCode: 500,
    status: "Failure",
    message: "Python Error. Please type the Another custom messages",
  };

  useEffect(() => {
    if (allPredefine.length > 0) {
      setRemainingQuestions(allPredefine);
      setCurrentQuestions(pickRandomQuestions(allPredefine, answeredQuestions));
    }
  }, [allPredefine, answeredQuestions]);

  useEffect(() => {
    if (answeredQuestions.length > 0) {
      setShowClickQuestion(true);
      setShowIntro(false);
      setCurrentQuestions(pickRandomQuestions(allPredefine, answeredQuestions));
    }
  }, [answeredQuestions]);

  // const pickRandomQuestions = (
  //   allQuestions: AlLPredefinedQuestions[],
  //   exclude: PredefinedQuestions[],
  //   count: number = 4
  // ): PredefinedQuestions[] => {
  //   // console.log(allQuestions, "allQuestions");
  //   // console.log(exclude, "exclude");

  //   const availableQuestions =
  //     allQuestions.filter(
  //       (question) =>
  //         !exclude.some(
  //           (excluded) => question.pre_questions === excluded.pre_questions
  //         )
  //     );
  //   // console.log(availableQuestions, "availableQuestions");
  //   setAvailableQuestions(availableQuestions);
  //   if (availableQuestions.length == 0) {
  //     setOverture(false);
  //     return [];
  //   } else {
  //     setOverture(true);
  //     const shuffled = availableQuestions.sort(() => 0.5 - Math.random());
  //     return shuffled.slice(0, count);
  //   }
  // };

  const pickRandomQuestions = (
    allQuestions: PredefinedQuestions[],
    exclude: PredefinedQuestions[],
    count: number = 3
  ): PredefinedQuestions[] => {
    // const availableQuestions = allQuestions.filter(
    //   (question) =>
    //     !exclude.some(
    //       (excluded) => excluded.pre_question_id === question.pre_question_id
    //     )
    // );

    const shuffled = allQuestions.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const handleQuestionClick = async (
    clickedQuestion: QuestionType,
    threadId: any
  ) => {
    setShowClickQuestion(true);
    // function is assign inthis code
    refreshNewChat();

    // Determine if clickedQuestion is a predefined question or a string
    const isPredefinedQuestion = (
      question: QuestionType
    ): question is PredefinedQuestion => {
      return (question as PredefinedQuestion).pre_question_id !== undefined;
    };
    // setTimingIssue(true)

    setCurrentAnswerTyped(true);

    try {
      const inputDateString = new Date().toISOString();
      const utcDate = convertUtcToIst(inputDateString);

      if (isPredefinedQuestion(clickedQuestion)) {
        setIsTypingComplete(false);
        setShowIntro(false);

        const response = await axiosPrivate.get(
          `${process.env.REACT_APP_BASE_URL}/answer/${clickedQuestion.pre_question_id}`
        );

        if (response.status == 200) {
          const responseData = response.data;
          // console.log(responseData, "responseData");
          // console.log("Data sent successfully");

          // console.log(
          //   `${process.env.REACT_APP_BASE_URL}/answer/${clickedQuestion.pre_question_id}`
          // );
          const answerBody = {
            threadId: ThreadId,
            messageContent: responseData[0].pre_answers,
            messageType: "response",
            createdOn: utcDate,
          };
          const answerResponse = await axiosPrivate.post(
            `${process.env.REACT_APP_BASE_URL}/threadmsg/messageThread`,
            answerBody
          );
          if (answerResponse.status == 200) {
            const questionBody = {
              threadId: ThreadId,
              messageContent: clickedQuestion.pre_questions,
              messageType: "request",
              createdOn: utcDate,
            };

            const questionResponse = await axiosPrivate.post(
              `${process.env.REACT_APP_BASE_URL}/threadmsg/messageThread`,
              questionBody
            );

            if (questionResponse.status == 200) {
              setResponseQuestionTiming((prevResponseQuestionTiming: any) => [
                ...prevResponseQuestionTiming,
                utcDate,
              ]);
              setResponseAnswer((prevResponseAnswer: any) => [
                ...prevResponseAnswer,
                ...responseData,
              ]);
              setResponseAnswerTiming((prevResponseAnswerTiming: any) => [
                ...prevResponseAnswerTiming,
                utcDate,
              ]);

              const updatedAnsweredQuestions = [
                ...answeredQuestions,
                clickedQuestion,
              ];
              // console.log(updatedAnsweredQuestions);

              const newQuestions = pickRandomQuestions(
                allPredefine,
                updatedAnsweredQuestions ? answeredQuestions : []
              );
              // console.log(newQuestions);
              setClickedQuestions((prevClickedQuestions) => [
                ...prevClickedQuestions,
                clickedQuestion,
              ]);
              setAnsweredQuestions(updatedAnsweredQuestions);
              setCurrentQuestions(newQuestions);
              setMessage("");
              // console.log(availableQuestions.length);

              if (availableQuestions.length == 1) {
                setShowFinalMessage(true);
              }
              // setFinalQuestionMessage(true);
            } else {
              console.error(
                "Failed to retrieve data",
                questionResponse.statusText
              );
            }
          } else {
            console.error("Failed to retrieve data", response.statusText);
          }
        } else {
          console.log("predefined answer get failed");
        }
      } else {
        console.log("clicked question", clickedQuestion);

        if (message == "" && ThreadId) {
          return;
        }
        setIsTypingComplete(false);
        setShowIntro(false);
        // Handle simple string question
        // console.log("Handling simple string question:", clickedQuestion);

        // console.log("clicked question", clickedQuestion);

        // console.log(responseData);
        try {
          const pythonQuestion = {
            query: clickedQuestion,
          };
          // console.log(pythonQuestion);

          // const newResponse = {
          //   answer:
          //     "Ashaa AI was created by Ashaa Technology Solutions from Chennai, India.",
          //   score: 0.8067464232444763,
          // };
          console.log(pythonQuestion);

          const pythonNewResponse = await axiosPrivate.post(`https://king-prawn-app-pjzvc.ondigitalocean.app/get_answer/`, pythonQuestion)
          console.log(pythonNewResponse);

          if (pythonNewResponse.status == 200) {
            // const responseData = await pythonQuestionResponse.data();
            const newResponse = await pythonNewResponse.data;
            console.log(newResponse, "Python Response");
            const pythonAnswerBody = {
              threadId: ThreadId,
              messageContent: newResponse.answer,
              messageType: "response",
              createdOn: utcDate,
            };
            // console.log(pythonAnswerBody);

            const pythonAnswerResponse = await axiosPrivate.post(
              `${process.env.REACT_APP_BASE_URL}/threadmsg/messageThread`,
              pythonAnswerBody
            );
            // console.log(pythonAnswerResponse.ok);
            if (pythonAnswerResponse.status == 200) {
              const responseData = await pythonAnswerResponse.data;
              // console.log(responseData);
              const pythonQuestionBody = {
                threadId: ThreadId,
                messageContent: clickedQuestion,
                messageType: "request",
                createdOn: utcDate,
              };
              // console.log(pythonQuestionBody);

              const pythonQuestionResponse = await axiosPrivate.post(
                `${process.env.REACT_APP_BASE_URL}/threadmsg/messageThread`,
                pythonQuestionBody
              );
              // console.log(pythonQuestionResponse.ok);
              if (pythonQuestionResponse.status == 200) {
                setResponseQuestionTiming((prevResponseQuestionTiming: any) => [
                  ...prevResponseQuestionTiming,
                  utcDate,
                ]);

                const transformedQuestion = {
                  pre_questions: clickedQuestion, // Transform string into the desired object structure
                };
                // console.log(transformedQuestion);

                setResponseAnswer((prevResponseAnswer: any) => [
                  ...prevResponseAnswer,
                  newResponse,
                ]);
                setResponseAnswerTiming((prevResponseAnswerTiming: any) => [
                  ...prevResponseAnswerTiming,
                  utcDate,
                ]);
                setClickedQuestions((prevClickedQuestions: any) => [
                  ...prevClickedQuestions,
                  transformedQuestion,
                ]);
                const updatedAnsweredQuestions: any = [
                  ...answeredQuestions,
                  transformedQuestion,
                ];
                const newQuestions = pickRandomQuestions(
                  allPredefine,
                  updatedAnsweredQuestions ? answeredQuestions : []
                );
                setAnsweredQuestions(updatedAnsweredQuestions);
                setCurrentQuestions(newQuestions);
                setMessage("");
              } else {
                // alert("python answer insert failed ")
                console.error(
                  "Failed to send data",
                  pythonQuestionResponse.statusText
                );
              }
            } else {
              console.error(
                "Failed to send data",
                pythonAnswerResponse.statusText
              );
              // alert("python question insert failed ")
            }
          } else {
            console.error("Failed to send data", pythonNewResponse.statusText);
            // setShowPythonErrorMessage(true);
            setMessage("");
            setIsTypingComplete(true);
          }
        } catch (error) {
          // setShowPythonErrorMessage(true);
          setMessage("");
          setIsTypingComplete(true);
        }
      }
    } catch (error) {
      console.error(error);

      console.error("Error retrieving data:", error);
    }
  };

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyDown = (e: any) => {
    if (isTypingComplete) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleQuestionClick(message, ThreadId);
      }
    } else {
      // only enter shifht called
      if (e.key === "Enter" && e.shiftKey) {
        e.preventDefault();
        handleQuestionClick(message, ThreadId);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    // adjustTextareaHeight();
  };

  // const adjustTextareaHeight = () => {
  //   if (textareaRef.current) {
  //     textareaRef.current.style.height = "auto"; // Reset height
  //     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set new height
  //     if (textareaRef.current.scrollHeight > 200) {
  //       // Replace 200 with your desired max height
  //       textareaRef.current.style.height = "200px"; // Set max height
  //       textareaRef.current.style.overflowY = "scroll"; // Enable vertical scrolling
  //     } else {
  //       textareaRef.current.style.overflowY = "hidden"; // Hide scrollbar if below max height
  //     }
  //   }
  // };

  // // Adjust the height when the component mounts
  // useEffect(() => {
  //   adjustTextareaHeight();
  // }, [message]);

  const chatboxContentRef = useRef<HTMLDivElement>(null);

  // Scroll to the bottom whenever the location changes (page navigation)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (chatboxContentRef.current) {
        chatboxContentRef.current.scrollTop =
          chatboxContentRef.current.scrollHeight;
      }
    }, 100); // Adjust the timeout if needed

    return () => clearTimeout(timer); // Clean up the timer
  }); // Dependencies that cause updates

  return (
    <section id="Chatbox">
      <div>
        <div className="chatbox-container" style={{ marginTop:role_id == 1 || role_id == 2 ? "35px" : "0px" }}>
          <div className="chatbox-content" ref={chatboxContentRef}>
            {showIntro && showNewChat && answeredQuestions.length < 0 && (
              <div>
                <h2>How can I help you today?</h2>
                <p>
                  Would you like more information on the recommended board, or
                  do you have any other specific questions about your child's
                  education?
                </p>
              </div>
            )}
            {showClickQuestion && (
              <div>
                {responseAnswer?.map((response: any, index: number) => {
                  // console.log(response.pre_questions, "response");
                  // console.log(answeredQuestions[index]?.created_on);

                  // console.log(
                  //   response.pre_questions,
                  //   "response.pre_questions"
                  // );

                  const foundItem: any = allPredefine?.find((item: any) => {
                    // console.log(item.pre_questions, response.pre_questions);

                    return item?.pre_questions === response?.pre_questions;
                  });
                  // console.log(foundItem);
                  return (
                    <React.Fragment key={response.pre_questions}>
                      <div className="row justify-content-start">
                        <div className="col-lg-12 text-right">
                          {foundItem ? (
                            <>
                              <div className="chatbox_show_question">
                                <p className="text-start" style={{margin:"0"}}>
                                  {" "}
                                  <span className="fw-bold">
                                    {user_name ? user_name : "ashaa User"}{" "}
                                  </span>
                                  <span style={{ fontSize: "12px" }}>
                                    {responseQuestionTiming[index]
                                      ? responseQuestionTiming[index]
                                      : convertUtcToIst(
                                          answeredQuestions[index]?.created_on
                                        )}
                                  </span>{" "}
                                </p>
                                {foundItem.pre_questions}
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                className="chatbox_show_question"
                                //     data-bs-toggle="tooltip"
                                //  data-bs-placement="top" // Tooltip position (top, bottom, left, right)
                                //  title=   {responseQuestionTiming[index]
                                //   ? responseQuestionTiming[index]
                                //   : convertUtcToIst(
                                //     answeredQuestions[index]?.created_on
                                //   )} // Tooltip text
                                // style={{fontWeight: '600'}}
                              >
                                <p className="text-start" style={{margin:"0"}}>
                                  {" "}
                                  <span className=" fw-bold">
                                    {user_name ? user_name : "ashaa User"}{" "}
                                  </span>
                                  <span style={{ fontSize: "12px" }}>
                                    {responseQuestionTiming[index]
                                      ? responseQuestionTiming[index]
                                      : convertUtcToIst(
                                          answeredQuestions[index]?.created_on
                                        )}
                                  </span>{" "}
                                </p>
                                {answeredQuestions[index]?.pre_questions}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row justify-content-start">
                        <div className="col-lg-12 text-right">
                          <div className="chatbox-answer">
                            <p>
                              <span className="fw-bold">ashaa AI </span>
                              <span style={{ fontSize: "12px" }}>
                                {responseAnswerTiming[index]
                                  ? responseAnswerTiming[index]
                                  : convertUtcToIst(
                                      answeredQuestions[index]?.created_on
                                    )}
                              </span>
                            </p>
                            {currentAnswerTyped &&
                            index === responseAnswer.length - 1 ? (
                              <p className={isTypingComplete ? "mb-5" : ""}>
                              <TypingText
                                text={response.pre_answers || response.answer}
                                onComplete={() => setIsTypingComplete(true)}
                                speed={10}
                              />
                              </p>
                            ) : (
                              <p className={responseAnswer.length - 1 == index ? "mb-5" : ""}>{response.pre_answers || response.answer}</p>
                              
                            )}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            )}

            {/* setinder val is after 2 sec after show this code  */}
            {showNewChat && isTypingComplete && (
              <>
                {/* <h3>Examples</h3> */}
                <div className="row justify-content-between mx-1" style={{borderTop: '1px solid #ccc'}}>
                  {currentQuestions.map((item, index) => (
                    console.log(index,"index"),
                    <div
                      className="chatbox-question d-flex justify-content-between mb-1" style={{ marginTop: index == 0 ? '1em' : '0.5em' }}
                      key={item.pre_question_id}
                      onClick={() => handleQuestionClick(item, ThreadId)}
                    >
                      <p
                        data-bs-toggle="tooltip"
                        data-bs-placement="top" // Tooltip position (top, bottom, left, right)
                        title="Predefined Questions" // Tooltip text
                        style={{ fontWeight: "600",fontSize: "14px", margin: "0" }}
                      >
                        <FaBolt
                          style={{
                            marginRight: "8px",
                            color: "#0d6efd",
                            fontSize: "14px",
                          }}
                        />
                        {item.pre_questions}
                       
                      </p>
                      <span className="me-1">
                      <FaGreaterThan
                          style={{
                            marginRight: "8px",
                            color: "var(--Colours-Primary-colour-Blue-00)",
                            fontSize: "12px",
                          }}
                        />
                        </span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>

          
           
        </div>
         <div className="chatbox_fixed pe-5 ps-4">
              <div className="input-box">
                <textarea
                  ref={textareaRef}
                  rows={1}
                  value={message}
                  className="col-lg-12"
                  // disabled={!isTypingComplete ? true : false}
                  onChange={handleChange}
                  placeholder={
                    answeredQuestions.length == 0
                      ? "Ask ashaa anything . . ."
                      : "Reply to ashaa . . ."
                  }
                  // "Ask ashaa anything . . ."
                  onKeyDown={handleKeyDown}
                  style={{
                    overflow: "hidden", // Prevent scrollbar
                    resize: "none", // Disable manual resizing
                    maxHeight: "200px",
                  }}
                />
                {isTypingComplete ? (
                  // <svg
                  //   xmlns="http://www.w3.org/2000/svg"
                  //   width="2em"
                  //   height="3em"
                  //   viewBox="0 0 64 64"
                  //   className="chat-btn cursor-pointer"
                  //   onClick={() => handleQuestionClick(message, ThreadId)}
                  // >
                  //   <path
                  //     fill={
                  //       message
                  //         ? "var(--Colours-Neutral-colours-black-10)"
                  //         : "var(--Colours-Neutral-colours-Gray-100)"
                  //     }
                  //     d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m5.143 28.305V49H26.857V30.305H16L32 15l16 15.305z"
                  //   />
                  // </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="3em"
                    viewBox="0 0 1792 1792"
                    className="chat-btn cursor-pointer"
                    onClick={() => handleQuestionClick(message, ThreadId)}

                  >
                    <path
                      fill={
                              message
                                ? "var(--Colours-Neutral-colours-black-10)"
                                : "var(--Colours-Neutral-colours-Gray-100)"
                            }
                      d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45q-14 8-31 8q-11 0-24-5l-453-185l-242 295q-18 23-49 23q-13 0-22-4q-19-7-30.5-23.5T640 1728v-349l864-1059l-1069 925l-395-162q-37-14-40-55q-2-40 32-59L1696 9q15-9 32-9q20 0 36 11"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="2em"
                    height="3em"
                    viewBox="0 0 20 20"
                    className="chat-btn"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                  >
                    <title>Not Clikable.</title>
                    <path
                      fill="black"
                      d="M10 3a7 7 0 1 0 0 14a7 7 0 0 0 0-14m-8 7a8 8 0 1 1 16 0a8 8 0 0 1-16 0m5-2a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1z"
                    />
                  </svg>
                )}
              </div>
              {/* <p className="text-center">
                ChatGPT can make mistakes. Check important info.
              </p> */}
            </div>
          
      </div>

      {showFinalMessage && (
        <CloseToast
          messages={showFinalMessageToast}
          onClose={() => setShowFinalMessage(false)}
        />
      )}

      {showPythonErrorMessage && (
        <Toast
          messages={showPythonErrorlMessageToast}
          onClose={() => setShowPythonErrorMessage(false)}
        />
      )}
    </section>
  );
};

export default Chatbox;
