import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/Breadcrumb.scss";
import { stateContext } from "../utils/stateContext";
interface BreadcrumbProps { }

const Breadcrumb: React.FC<BreadcrumbProps> = () => {
  const {
    state: {
      isLogin,
      logsView,
      user_Data: { user_name, role_id, user_id },
    },
    dispatch,
  } = useContext(stateContext);
console.log(isLogin);




  // console.log(user_name, role_id, user_id);

  const location = useLocation();
  console.log(location);
  
  
  const navigate = useNavigate();

  // console.log(location);
  const [content, setContent] = React.useState<any>([]);

  // get the path and split it into segments based on '/' and filter out empty segments (leading or trailing slashes)
  const pathLocal = sessionStorage.setItem(
    "pathLocal",
    JSON.stringify(location?.pathname)
  );
  // console.log(pathLocal);

  const pathSegments = location?.pathname
    .split("/")
    .filter((segment) => segment !== "");
  // console.log(pathSegments);



  let numericSegments: string[] = [];
  let textSegments: string[] = [];
  // console.log(pathSegments);
  // console.log(pathSegments[0]);
  // console.log(textSegments);

  // number and text segments will be stored in separate arrays

  pathSegments.forEach((segment) => {
    if (!isNaN(Number(segment))) {
      numericSegments.push(segment);
    } else {
      textSegments.push(segment);
    }
  });

  // Make sure to call getSolution inside a useEffect or another appropriate React hook

  useEffect(() => {
    handleBreadcrumbClick();
  }, [location]);

  const handleBreadcrumbClick = () => {
    // Define a mapping of path segments to content
    const contentMap: { [key: string]: string } = {
      Chatbot: "Chatbot",
      Profile: "Profile",
      Reports: "Reports",
      Logs: "Logs",
      Users: "Users",
    };

    // Check if pathSegments is defined and get the first segment
    const firstSegment = pathSegments ? pathSegments[0] : "Home";

    // Set content based on the first segment or default to "Home"
    setContent(contentMap[firstSegment] || "Home");
  };

  // const renderIcons = () => (
  //   <>
  //     <span>
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="1.5em"
  //         height="2em"
  //         viewBox="0 0 24 24"
  //       >
  //         <path
  //           fill="black"
  //           d="m20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8a7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42M5 11a6 6 0 1 1 6 6a6 6 0 0 1-6-6"
  //         />
  //       </svg>
  //     </span>
  //     <span className="ms-3">
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         width="1.5em"
  //         height="2em"
  //         viewBox="0 0 24 24"
  //       >
  //         <path
  //           fill="black"
  //           d="M4 8a8 8 0 1 1 16 0v4.697l2 3V20h-5.611a4.502 4.502 0 0 1-8.777 0H2v-4.303l2-3zm5.708 12a2.5 2.5 0 0 0 4.584 0zM12 2a6 6 0 0 0-6 6v5.303l-2 3V18h16v-1.697l-2-3V8a6 6 0 0 0-6-6"
  //         />
  //       </svg>
  //     </span>
  //   </>
  // );

  return (
    <section id="breadcrumb_container">
    <div className="Breadcrumb bg-white" style={{padding:role_id == 1 || role_id == 2 ? " 0px 10px 15px 0" : "0px 10px 0px 0"}}>
      <div
        className="bg-white px-3"
        style={{ width: "100%", height: "10vh", marginTop: "1em" }}
      >
        {content === "Home" && (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className={role_id == 1 || role_id == 2 ? "mt-4" : "mt-3"}>
                Welcome , <span style={{ fontSize: "1em", marginBottom: "1em" }}>{user_name}</span>
              </h2>
            </div>
            {/* <div className="icons">{renderIcons()}</div> */}
          </div>
        )}
        {content === "Chatbot" && (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              {/* <h2 className="breadcrumb-item">Untitled </h2> */}
              <h2 className="breadcrumb-item mt-2">
                Welcome , <span style={{ fontSize: "1em" }}>Ashaa</span>
              </h2>
            </div>

          </div>
        )}
        {content === "Profile" && (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className="breadcrumb-item">My Profile </h2>
              <p>Information that will power your Asha experience.</p>
            </div>
            {/* <div className="icons">{renderIcons()}</div> */}
          </div>
        )}
        {content === "Reports" && (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className="breadcrumb-item">
                Welcome , <span style={{ fontSize: "1em" }}>{user_name}</span>
              </h2>
            </div>
            {/* <div className="icons">{renderIcons()}</div> */}
          </div>
        )}
        {content === "Logs" && (
          <div className="d-flex align-items-center justify-content-between">
            <div className={role_id == 1 || role_id == 2 ? "" : "mt-1"}>
              <h4 className="breadcrumb-item fw-bold">Logs </h4>
            </div>
            <div className="icons">
              { logsView &&
                <button className="breadcrumb-btn btn-outline-secondary" onClick={() => dispatch({ type: "LOGS_VIEW", payload: false })}>
                  <span className="me-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="1.8em"
                      viewBox="0 0 24 24"
                    >
                      <g
                        fill="none"
                        stroke="black"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      >
                        <path d="m13 19l-6-7l6-7" />
                        <path d="m17 19l-6-7l6-7" />
                      </g>
                    </svg>
                  </span>
                  Back to All Logs
                </button>
              }
            </div>
          </div>
        )}
        {content === "Users" && (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h2 className="fw-bold">
                User Settings
              </h2>
            </div>
            <div className="icons">
              {" "}
              <span className="me-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.5em"
                  height="2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="black"
                    d="M4 8a8 8 0 1 1 16 0v4.697l2 3V20h-5.611a4.502 4.502 0 0 1-8.777 0H2v-4.303l2-3zm5.708 12a2.5 2.5 0 0 0 4.584 0zM12 2a6 6 0 0 0-6 6v5.303l-2 3V18h16v-1.697l-2-3V8a6 6 0 0 0-6-6"
                  />
                </svg>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
    </section>
  );
};
export default Breadcrumb;
