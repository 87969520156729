import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaCalendar,
  FaCalendarAlt,
  FaClock,
  FaCog,
  FaEye,
  FaFile,
  FaGreaterThan,
  FaLessThan,
  FaSellcast,
  FaUser,
} from "react-icons/fa";
import "../../Styles/Logs.scss";
import { formatUtcDate, getTimeDifference } from "../../utils/CustomFunctions";
import { stateContext } from "../../utils/stateContext";
import useAxiosPrivate from "../../utils/useAxiosPrivate";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Toast from "../../common/Toast";
import CloseToast from "../../common/CloseToast";

interface LogItem {
  user_id: number;
  user_name: string;
  first_thread_name: string;
  user_created_on: string;
  first_thread_created_on: string | null;
  first_message_content: string;
  last_message_content: string | null;
  last_message_created_on: string | null;
  thread_id: number;
}

interface ThreadItem {
  last_message_content: string;
  user_id: number;
  thread_id: number;
  thread_name: string;
  thread_created_on: string;
  first_message_id: number | null;
  first_message_content: string | null;
  first_message_type: string | null;
  last_message_created_on: string | null;
}

interface Message {
  thread_id: number;
  user_id: number;
  thread_name: string;
  message_id: number;
  message_content: string;
  message_type: string;
  created_on: string;
}

const isThreadItem = (item: LogItem | ThreadItem): item is ThreadItem => {
  return (item as ThreadItem).thread_name !== undefined;
};
type User = {
  user_id: number;
  user_name: string;
  role_id: number;
  email_id: string;
};

const Logs: React.FC = () => {
  const {
    state: {
      token,
      logsView,
      user_Data: { user_id, role_id, user_name },
    },
    dispatch,
  } = useContext(stateContext);
  console.log(logsView, "logView");
  // let user_id = 1;
  // console.log(user_id, "user_id");
  // console.log(user_name, "user_name");

  const axiosPrivate = useAxiosPrivate();

  const [logs, setLogs] = useState<LogItem[]>([]);
  // console.log(logs, "logs");

  const [threads, setThreads] = useState<ThreadItem[]>([]);
  // console.log(threads, "threads");

  const [messages, setMessages] = useState<Message[]>([]); // New state for messages
  console.log(messages, "messages");

  const [selectedId, setSelectedId] = useState<string>(
    sessionStorage.getItem("selectedId") || ""
  );
  // console.log(selectedId, "selectedId");
  const [selectedUser, setSelectedUser] = useState<string>("");

  console.log(selectedUser, "selectedUser");

  const [uniqueUsers, setUniqueUsers] = useState<any>([]);
  // console.log(uniqueUsers, "uniqueUsers");
  // this code based untiques user wise user id based filter the name
  const defaultUser = logs.find((log) => log.user_id == user_id);
  // console.log(user_id);
  //
  // console.log(defaultUser, "defaultUser");
  const [allUsers, setAllUsers] = useState<any[]>([]);
  // console.log(allUsers, "allUsers");

  const [currentPage, setCurrentPage] = useState(1);
  // console.log(currentPage, "currentPage");

  const [itemsPerPage, setItemsPerPage] = useState<any>(5);
  // console.log(itemsPerPage, "itemsPerPage");
  // console.log(uniqueUsers.length, "uniqueUsers.length");

  // Calculate the total number of pages based on items per page
  const totalPages = Math.ceil(allUsers.length / itemsPerPage);
  // console.log(totalPages, "totalPages");

  // Get the users to display based on the current page
  const indexOfLastUser: any = currentPage * itemsPerPage;
  // console.log(indexOfLastUser, "indexOfLastUser");

  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  // console.log(indexOfFirstUser, "indexOfFirstUser");

  const currentUsers = uniqueUsers.slice(indexOfFirstUser, indexOfLastUser);
  // console.log(currentUsers, "currentUsers");

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState<any>("");

  const filterUserName = (userId: number) => {
    // console.log(userId, "userId");

    const user = logs.find((log) => log.user_id == userId);
    // console.log(user, "user");
    return user?.user_name;
  };

  // console.log(filterUserName(user_id), "filterUserName");

  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [isLimitDropdownOpen, setIsLimitDropdownOpen] =
    useState<boolean>(false);
  const [selectedThreadId, setSelectedThreadId] = useState<number | null>(null); // New state for selected thread ID
  const [UserThread, setUserThread] = useState<any[]>([]);

  useEffect(() => {
    const fetchUserThreads = async () => {
      try {
        const response = await axiosPrivate.get(`/threadmsg`);
        setAllUsers(response.data);
      } catch (error) {
        console.error("Error fetching user threads:", error);
      }
    };

    fetchUserThreads();
  }, [user_id]);

  useEffect(() => {
    const fetchUserThreads = async () => {
      try {
        const response = await axiosPrivate.get(`/threadmsg/${user_id}`);
        setUserThread(response.data);
      } catch (error) {
        console.error("Error fetching user threads:", error);
      }
    };

    fetchUserThreads();
  }, [axiosPrivate, user_id]);

  // console.log(UserThread, "UserThread");

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        // console.log(itemsPerPage, "itemsPerPage");
        // console.log(indexOfFirstUser, "indexOfFirstUser");
        // console.log(selectedUser, "selectedUser");
        const response = await axiosPrivate.get(
          // `/threadmsg?limit=${limit}`);
          selectedUser != ""
            ? `/threadmsg`
            : `/threadmsg?limit=${itemsPerPage}&offset=${indexOfFirstUser}`
        );
        if (response.status == 200) {
          // console.log(response, "response");

          const data: LogItem[] = response.data;
          // Create a map to store unique users by user_name
          const userMap = new Map();
          data.forEach((log: LogItem) => {
            if (!userMap.has(log.user_name)) {
              userMap.set(log.user_name, {
                user_id: log.user_id,
                user_name: log.user_name,
              });
            }
          });

          // Convert the map values to an array
          const usersWithId: any = Array.from(userMap.values());

          // Set the unique users
          setUniqueUsers(usersWithId);
          setLogs(data);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, [itemsPerPage, indexOfFirstUser, selectedUser]);

  useEffect(() => {
    const fetchThreads = async () => {
      if (!selectedUser) return;

      const user = logs.find((log) => log.user_name === selectedUser);
      if (!user) return;

      try {
        const response = await axiosPrivate.get(
          `${process.env.REACT_APP_BASE_URL}/threadmsg/${user.user_id}?limit=${limit}`
        );
        if (response.status == 200) {
          const data: ThreadItem[] = response.data;
          setThreads(data);
        }
      } catch (error) {
        console.error("Error fetching threads:", error);
      }
    };

    fetchThreads();
  }, [selectedUser, limit, logs, axiosPrivate]);

  // const viewClick = async (threadId: number, userId: number) => {
  //   console.log(threadId, "threadId");
  //   console.log(userId);
  //   const userNameGet = filterUserName(userId);
  //   console.log(userNameGet, "userNameGet");

  //   // console.log(`${process.env.REACT_APP_BASE_URL}/threadmsg/${userId}/${threadId}`);

  //   try {
  //     const response = await axiosPrivate.get(
  //       `${process.env.REACT_APP_BASE_URL}/threadmsg/${userId}/${threadId}`
  //     );
  //     if (response.status == 200) {
  //       const data: Message[] = response.data;
  //       console.log(data, "data");

  //       setMessages(data);
  //       // console.log(data, "data");
  //       setSelectedThreadId(threadId); // Set the selected thread ID
  //       setSelectedUser(userNameGet?.toString() ?? "");
  //       sessionStorage.setItem("threadId", data[0].thread_id.toString());
  //       sessionStorage.setItem("userId", userId.toString());
  //       dispatch({ type: "LOGS_VIEW", payload: true });
  //     }
  //   } catch (error) {
  //     dispatch({ type: "NETWORK_ERROR", payload: true });
  //     console.error("Error fetching messages:", error);
  //   }
  // };

  const viewClick = async (threadId: number, userId: number) => {
    console.log(threadId, "threadId");
    console.log(userId);

    // Assume filterUserName returns a string or undefined
    const userNameGet = filterUserName(userId);
    console.log(userNameGet, "userNameGet");

    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BASE_URL}/threadmsg/${userId}/${threadId}`
      );

      if (response.status === 200) {
        const data: Message[] = response.data;
        console.log(data, "data");

        setMessages(data);
        setSelectedThreadId(threadId); // Set the selected thread ID

        // Ensure the setSelectedUser always receives a string value
        setSelectedUser(userNameGet?.toString() ?? ""); // Use empty string if userNameGet is undefined

        // Save threadId and userId to sessionStorage
        sessionStorage.setItem("threadId", data[0].thread_id.toString());
        sessionStorage.setItem("userId", userId.toString());
        sessionStorage.setItem("userName", userNameGet?.toString() ?? "");

        // Dispatch the log view action
        dispatch({ type: "LOGS_VIEW", payload: true });
      }
    } catch (error) {
      dispatch({ type: "NETWORK_ERROR", payload: true });
      console.error("Error fetching messages:", error);
    }
  };

  // user point click
  const sessionThreadId = sessionStorage.getItem("threadId");
  // console.log(sessionThreadId, "sessionThreadId");
  const sessionUserId = sessionStorage.getItem("userId");
  console.log(sessionUserId, "sessionUserId");

  console.log(sessionThreadId, "sessionThreadId");

  // console.log(user_id, "user_id");

  useEffect(() => {
    // console.log(user_id, "user_id");

    const fetchSesstionID = async () => {
      try {
        const response = await axiosPrivate.get(
          `${process.env.REACT_APP_BASE_URL}/threadmsg/${
            role_id == 1 || role_id == 2 ? sessionUserId : user_id
          }/${sessionThreadId}`
        );
        if (response.status == 200) {
          const sessionIdvalue = response.data;
          // alert(sessionIdvalue);
          // console.log(sessionIdvalue, "sessionIdvalue");
          setMessages(sessionIdvalue);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchSesstionID();
  }, [user_id, sessionThreadId, axiosPrivate, sessionUserId]);

  useEffect(() => {
    if (logsView == true) setItemsPerPage(0);
  }, [logsView]);

  const viewUserClick = async (userId: number, threadId: number) => {
    // console.log(userId, threadId, "userId, threadId");

    try {
      const response = await axiosPrivate.get(
        `${process.env.REACT_APP_BASE_URL}/threadmsg/${threadId}/${userId}`
      );
      if (response.status == 200) {
        const data = response.data;
        // console.log(data, "data");

        // setMessages(data);
        setSelectedThreadId(threadId); // Set the selected thread ID
        sessionStorage.setItem("threadId", data[0].thread_id.toString());
        dispatch({ type: "LOGS_VIEW", payload: true });
      }
    } catch (error) {
      dispatch({ type: "NETWORK_ERROR", payload: true });
      console.error("Error fetching messages:", error);
    }
  };

  const handleUserSelect = (userName: any) => {
    if (userName.user_name) {
      setSelectedUser(userName.user_name);
      setSelectedId(userName.user_id);
      setItemsPerPage(0);
      sessionStorage.setItem("userId", userName.user_id.toString());
    } else {
      // Handle case for "All Users" or when userName is an empty string
      setSelectedUser("");
      setSelectedId(""); // or set to null
      setItemsPerPage(0);
      sessionStorage.removeItem("userId");
    }
    setIsUserDropdownOpen(false);
  };

  const toggleUserDropdown = (isOpen: boolean) => {
    setIsUserDropdownOpen(isOpen);
  };

  const handleLimitSelect = (selectedLimit: number) => {
    setLimit(selectedLimit);
    setIsLimitDropdownOpen(false);
  };

  const toggleLimitDropdown = (isOpen: boolean) => {
    setIsLimitDropdownOpen(isOpen);
  };

  // Function to handle items per page selection from dropdown
  const handleLimitSelect1 = (limit: number) => {
    if (selectedUser != "") {
      setSelectedUser("");
    }
    setItemsPerPage(limit);
    setCurrentPage(1); // Reset to the first page when limit changes
  };

  // Function to handle next page click
  const handleNextPage = () => {
    // console.log(currentPage, "currentPage");
    // console.log(totalPages, "totalPages");

    if (currentPage < totalPages && selectedUser == "") {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle previous page click
  const handlePreviousPage = () => {
    if (currentPage > 1 && selectedUser == "") {
      setCurrentPage(currentPage - 1);
    }
  };

  // download option in logs
  const downloadFile = async (
    selectedUser: number,
    selectedMonth: number,
    selectedYear: string,
    userName: string,
    axiosPrivate: any // Accept axiosPrivate as a parameter
  ) => {
    console.log(
      `Downloading file for User: ${selectedUser}, Month: (${selectedMonth} + 1), Year: ${selectedYear}`
    );

    try {
      // Get thread id of user
      const rows = await axiosPrivate.post(
        `${process.env.REACT_APP_BASE_URL}/threadmsg/newThread`,
        {
          userId: selectedUser,
        }
      );

      if (rows.status === 200) {
        const thread_id = rows.data.data;
        const response = await axiosPrivate.get(
          `${
            process.env.REACT_APP_BASE_URL
          }/threadmsg/download?user_id=${selectedUser}&thread_id=${thread_id}&month=${
            selectedMonth + 1
          }&year=${selectedYear}`
        );
        // console.log(response.data);
        if (response.data.length !== 0) {
          // Define the headers for the new sheet
          const headers = ["Request", "Response", "Created On"];

          // Create a new workbook
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(userName); // Set the sheet name as user name

          // Add the header row
          const headerRow = worksheet.addRow(headers);

          // Apply bold style to the header row
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { wrapText: true };
          });

          // Add the data rows
          response.data.forEach(
            (item: {
              request: string;
              response: string;
              created_on: string;
            }) => {
              const rowValues = [
                item.request,
                item.response,
                new Date(item.created_on).toLocaleString(),
              ];
              worksheet.addRow(rowValues);
            }
          );

          // Set column widths and wrap text
          worksheet.columns.forEach((column) => {
            column.width = 30; // Set a fixed column width
            column.alignment = { wrapText: true };
          });

          // Generate the Excel file
          const buffer = await workbook.xlsx.writeBuffer();

          // Save the file using file-saver
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, `${userName}_logs.xlsx`);
        } else {
          // alert(
          //   "No logs for the selected user for the given month and year. Please choose another month and year"
          // );
          // alert("Please select a month.");
          setShowToast(true);
          setMessage({
            statusCode: 400,
            status: "Failure",
            message: "No logs found for this user in the selected timeframe.",
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const [users, setUsers] = useState<User[]>([]); // User list from API
  console.log(users, "users");

  // alert(users)
  // const [selectedUser, setSelectedUser] = useState<string>(''); // Selected user
  const [selectedMonth, setSelectedMonth] = useState<string>(""); // Selected month
  const [selectedYear, setSelectedYear] = useState<string>("2024"); // Default year
  const [isMonthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [isYearDropdownOpen, setYearDropdownOpen] = useState(false);

  // Fetch user list from API on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosPrivate.get(
          `${process.env.REACT_APP_BASE_URL}/users`
        ); // Replace with actual API endpoint
        const data = response.data;
        setUsers(data); // Assuming data is an array of user names
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [axiosPrivate]);

  // Months array
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Years array
  const years = ["2024", "2025", "2026"];

  const handleDownload = () => {
    console.log(selectedUser, selectedMonth, selectedYear);
    // alert("Downloading logs...");
    if (selectedUser && selectedMonth && selectedYear) {
      // alert("Downloading logs1...");
      const user = users.find((u) => u.user_name === selectedUser);
      // console.log(user, "useraafdwefwwwwwwww");

      const userName = user?.user_name;
      const userId = user?.user_id;
      if (userName) {
        // Pass axiosPrivate to the downloadFile function
        downloadFile(
          userId ? userId : 0,
          Number(selectedMonth),
          selectedYear,
          userName,
          axiosPrivate
        );
      }
    } else {
      // FIRST MAIN USER CHOOSE MESSAGE IS SHOWED THEN NEXT MONTH AND YEAR IS CHOOSE MESSAGE IS SHOW ONE BY ONE
      if (!selectedUser) {
        // alert("Please select a user.");
        setShowToast(true);
        setMessage({
          statusCode: 400,
          status: "Failure",
          message: "please select a user",
        });
      } else if (!selectedMonth) {
        // alert("Please select a month.");
        setShowToast(true);
        setMessage({
          statusCode: 400,
          status: "Failure",
          message: "please select a month",
        });
      } else if (!selectedYear) {
        // alert("Please select a year.");
        setShowToast(true);
        setMessage({
          statusCode: 400,
          status: "Failure",
          message: "please select a year",
        });
      }
      // alert("Please select a user, month, and year.");
    }
  };

  // Toggle function for dropdown
  // const toggleMonthDropdown = () => setMonthDropdownOpen((prev) => !prev);
  const toggleMonthDropdown = (isOpen: boolean) => {
    setMonthDropdownOpen(isOpen);
  };

  // Function to handle month selection
  const handleMonthSelect = (month: string) => {
    setSelectedMonth(month);
    setMonthDropdownOpen(false);
  };

  // Toggle function for dropdown
  // const toggleYearDropdown = () => setYearDropdownOpen((prev) => !prev);
  const toggleYearDropdown = (isOpen: boolean) => {
    setYearDropdownOpen(isOpen);
  };

  // Function to handle year selection
  const handleYearSelect = (year: string) => {
    setSelectedYear(year);
    setYearDropdownOpen(false);
  };

  // Function to get user name based on session storage and role id
  const getUserName = () => {
    const sessionStorageName = sessionStorage.getItem("userName");
    console.log(sessionStorageName, "sessionStorageName");

    // Determine the user name based on the role id
    switch (role_id) {
      case 1:
      case 2:
        return sessionStorageName; // Use stored session user name for rolid 1 and 2
      case 3:
        return defaultUser?.user_name || ""; // Use defaultUser's name for rolid 3
      default:
        return user_name || "Guest"; // Fallback to user_name or a placeholder like "Guest"
    }
  };
  const isDropdownDisabled = selectedUser === "";

  return (
    <section className="logs-section">
      <>
        {role_id == 1 || role_id == 2 ? (
          logsView ? (
            <div className="messages-container">
              <div className="messages">
                {messages.length > 0 ? (
                  messages.map((message, index) =>
                    message.message_content ? (
                      <div
                        key={index}
                        className={`message-item ${message.message_type}`}
                      >
                        {/* created on is assgin  */}
                        {message.message_type == "request" ? (
                          <>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                {getUserName()}{" "}
                              </span>
                              <span style={{ fontSize: "12px" }}>
                                {formatUtcDate(message.created_on)}
                              </span>
                            </p>
                            <div className="message-content">
                              {message.message_content}
                            </div>
                          </>
                        ) : (
                          <>
                            <p>
                              <span style={{ fontWeight: "bold" }}>
                                ashaa AI{" "}
                              </span>
                              <span style={{ fontSize: "12px" }}>
                                {formatUtcDate(message.created_on)}
                              </span>
                            </p>

                            <div className="message-content">
                              {message.message_content}
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      <div key={index} className="no-messages">
                        <h4>Loading messages, please wait...</h4>
                      </div>
                    )
                  )
                ) : (
                  <div className="no-messages">
                    <h4 className="mt-5">Loading messages, please wait...</h4>
                  </div>
                )}
              </div>
            </div>
          ) : (
            // console.log("logsView", logsView),
            <div className="logs-container">
              <div className="dropdowns-container">
                <div className="user-dropdown col-lg-12">
                  <div className="d-flex">
                    <div className="col-lg-2 ms-2">
                    <Dropdown
                      show={isUserDropdownOpen}
                      onToggle={toggleUserDropdown}
                    >
                      <Dropdown.Toggle
                        as="div"
                        className="dropdown-custom-toggle"
                        onClick={() => toggleUserDropdown(!isUserDropdownOpen)}
                      >
                        <FaUser className="me-2" />
                        {selectedUser || "User"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleUserSelect("")}>
                        All Users
                      </Dropdown.Item>
                        {allUsers.map((user: any, index: number) => (
                          // console.log(user.user_name, "user"),

                          <Dropdown.Item
                            key={index}
                            onClick={() => handleUserSelect(user)}
                            className="dropdown-custom-item"
                          >
                            {user.user_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    </div>
                    <div className="perPageCount col-lg-7 d-block">
                      {/* Dropdown to select items per page */}
                      <Dropdown as={ButtonGroup} className="">
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="perPage"
                        >
                          <FaCog className="me-2" />
                          {/* Settings Icon */}
                          {itemsPerPage == 0 ? "Select" : itemsPerPage} per page
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="perPageMenu">
                          <Dropdown.Item onClick={() => handleLimitSelect1(5)}>
                            5 per page
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleLimitSelect1(10)}>
                            10 per page
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleLimitSelect1(30)}>
                            30 per page
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                     

                      {/* Pagination controls */}
                      <div className="pagination-controls d-inline-block">
                        <FaLessThan
                          onClick={handlePreviousPage}
                          fontSize={14}
                          style={{
                            cursor:
                              selectedUser != "" || currentPage === 1
                                ? "not-allowed"
                                : "pointer",
                            color:
                              selectedUser != "" || currentPage === 1
                                ? "var(--Colours-Neutral-colours-Gray-400)"
                                : "var(--Colours-Primary-colour-Blue-500)",
                            opacity:
                              selectedUser != "" || currentPage === 1 ? 0.5 : 1, // Use opacity to indicate disabled state
                          }}
                          className={
                            selectedUser != "" || currentPage === 1
                              ? "disabled"
                              : ""
                          }
                        >
                          {/* {"<"} */}
                        </FaLessThan>

                        <span className="mx-3">
                          {indexOfFirstUser + 1} -{" "}
                          {currentPage === totalPages
                            ? allUsers.length
                            : indexOfLastUser}
                        </span>
                        <FaGreaterThan
                          onClick={handleNextPage}
                          fontSize={14}
                          style={{
                            cursor:
                              selectedUser != "" || currentPage === totalPages
                                ? "not-allowed"
                                : "pointer",
                            color:
                              selectedUser != "" || currentPage === totalPages
                                ? "var(--Colours-Neutral-colours-Gray-400)"
                                : "var(--Colours-Primary-colour-Blue-500)",
                            opacity:
                              selectedUser != "" || currentPage === totalPages
                                ? 0.5
                                : 1, // Use opacity to indicate disabled state
                          }}
                          className={
                            selectedUser != "" || currentPage === totalPages
                              ? "disabled"
                              : ""
                          }
                        >
                          {/* {">"} */}
                        </FaGreaterThan>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="mx-2 download-dropdown col-lg-6">

                  <div className="d-flex align-items-center justify-content-between">
                  
                    <div className="month-dropdown col-lg-4">
                      <Dropdown
                        show={isMonthDropdownOpen}
                        onToggle={toggleMonthDropdown}
                      >
                        <Dropdown.Toggle
                          as="div"
                          // className="dropdown-custom-toggle"
                          className={`dropdown-custom-toggle ${
                            isDropdownDisabled ? "disabled-dropdown" : ""
                          }`}
                          style={{
                            cursor: isDropdownDisabled
                              ? "not-allowed"
                              : "pointer",
                          }}
                          // onClick={toggleMonthDropdown}
                          onClick={() =>
                            toggleMonthDropdown(!isMonthDropdownOpen)
                          }
                        >
                          <FaCalendarAlt className="me-2" />
                          {selectedMonth || "Select Month"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {months.map((month, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleMonthSelect(month)}
                              className="dropdown-custom-item"
                            >
                              {month}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
      
                    <div className="year-dropdown mx-2 col-lg-4">
                      <Dropdown
                        show={isYearDropdownOpen}
                        onToggle={toggleYearDropdown}
                        // disabled
                      >
                        <Dropdown.Toggle
                          as="div"
                          // className="dropdown-custom-toggle"
                          className={`dropdown-custom-toggle ${
                            isDropdownDisabled ? "disabled-dropdown" : ""
                          }`}
                          onClick={() =>
                            toggleYearDropdown(!isYearDropdownOpen)
                          }
                          disabled={isDropdownDisabled}
                        >
                          <FaCalendar className="me-2" /> 
                          {selectedYear || "Select Year"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {years.map((year, index) => (
                            <Dropdown.Item
                              key={index}
                              onClick={() => handleYearSelect(year)}
                              className="dropdown-custom-item"
                            >
                              {year}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    
                    <div className="col-lg-4">
                    <Button
                      style={{
                        backgroundColor:
                          "var(--Colours-Primary-colour-Blue-500)",
                        borderColor: "var(--Colours-Primary-colour-Blue-500)",
                        padding: "0.8rem 1rem",
                      }}
                      onClick={handleDownload}
                    >
                      Download
                    </Button>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="threads-container">
                {selectedUser === ""
                  ? logs.map(
                      (log, index) => (
                        console.log(log, "log"),
                        (
                          <div className="d-flex justify-content-between align-items-center thread-container-item">
                            <div key={index} className="thread-item" >
                              <div className="thread-name">
                                {/* Thread */}
                                {log.user_name}
                              </div>
                              <div className="thread-message">
                                {log.last_message_content}
                              </div>
                              <div className="thread-info">
                                <FaClock className="time-icon" />
                                <span className="created-time ms-3">
                                  {log.last_message_created_on
                                    ? getTimeDifference(
                                        log.last_message_created_on
                                      )
                                    : "No date"}
                                </span>
                                <span className="user-name">
                                  {/* {log.user_name} */}
                                  Thread
                                </span>
                              </div>
                            </div>
                            <div className="me-4">
                              <FaEye
                                className="action-icon"
                                title="View"
                                onClick={() =>
                                  viewClick(log.thread_id, log.user_id)
                                }
                              />
                            </div>
                          </div>
                        )
                      )
                    )
                  : threads.map((thread, index) => (
                      // console.log(thread),

                      <div key={index} className="thread-item">
                        <div className="thread-name">
                          {/* {thread.thread_name} */}
                          {/* Thread */}
                          {filterUserName(thread.user_id)}
                        </div>
                        <div className="thread-message">
                          {thread.last_message_content || "No message content"}
                          <div className="thread-actions">
                            <FaEye
                              className="action-icon"
                              title="View"
                              onClick={() =>
                                viewClick(thread.thread_id, thread.user_id)
                              }
                            />
                          </div>
                        </div>
                        <div className="thread-info">
                          <FaClock className="time-icon" />
                          <span className="created-time ms-3">
                            {thread.last_message_created_on
                              ? getTimeDifference(
                                  thread.last_message_created_on
                                )
                              : "No date"}
                          </span>
                          <span className="user-name">
                            {/* {filterUserName(thread.user_id)} */}
                            Thread
                          </span>
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )
        ) : (
          <div className="logs-container">
            {logsView ? (
              <div className="messages-container">
                <div className="messages">
                  {messages.length > 0 ? (
                    messages.map((message, index) =>
                      message.message_content ? (
                        // console.log(message.created_on),

                        <div
                          key={index}
                          className={`message-item ${message.message_type}`}
                        >
                          {message.message_type == "request" ? (
                            <>
                              <p className="">
                                <span style={{ fontWeight: "bold" }}>
                                  {defaultUser
                                    ? defaultUser?.user_name
                                    : user_name}{" "}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {formatUtcDate(message.created_on)}
                                </span>
                              </p>
                              <div className="message-content">
                                {message.message_content}
                              </div>
                            </>
                          ) : (
                            <>
                              <p>
                                <span style={{ fontWeight: "bold" }}>
                                  ashaa AI{" "}
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {formatUtcDate(message.created_on)}
                                </span>
                              </p>

                              <div className="message-content">
                                {message.message_content}
                              </div>
                            </>
                          )}
                          {/* <p>{formatUtcDate(message.created_on)}</p>
                          <div className="message-content">
                            {message.message_content}
                          </div> */}
                        </div>
                      ) : (
                        <div key={index} className="no-messages">
                          <h4>
                            No content available for this thread. Please click
                            the correct valuable thread or go back to logs.
                          </h4>
                        </div>
                      )
                    )
                  ) : (
                    <div className="no-messages">
                      <h4>{""}</h4>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <>
                {UserThread.map((thread: any, index: number) => (
                  // console.log(thread),
                  //   console.log(formatDistanceToNow(
                  //     new Date(thread.first_message_created_on),
                  //     { addSuffix: true }
                  //   )
                  // ),

                  <div key={index} className="thread-item mt-5">
                    <div className="thread-name">
                      {/* {thread.thread_name} */}
                      {/* Thread */}
                      {filterUserName(thread.user_id)}
                    </div>
                    <div className="thread-message">
                      {thread.last_message_content || "No message content"}
                      <div className="thread-actions">
                        <FaEye
                          className="action-icon"
                          title="View"
                          onClick={() =>
                            viewUserClick(thread.thread_id, thread.user_id)
                          }
                        />
                      </div>
                    </div>
                    <div className="thread-info">
                      <FaClock className="time-icon" />
                      <span className="created-time ms-3">
                        {thread.last_message_created_on
                          ? getTimeDifference(thread.last_message_created_on)
                          : "No date"}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )}
        {showToast && (
          <CloseToast messages={message} onClose={() => setShowToast(false)} />
        )}
      </>
    </section>
  );
};

export default Logs;
