import axios from 'axios';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { Route, HashRouter as Router, Routes } from 'react-router-dom';
import './App.scss';
import Breadcrumb from './common/Breadcrumb';
import ForgotPassword from './common/ForgotPassword';
import NetworkError from './common/NetworkError';
import Popup from './common/Popup';
import Sidebar from './common/Sidebar';
import Chatbot from './screen/Chatbot/Chatbot';
import EmailAddress from './screen/Login/EmailAddress';
import Login from './screen/Login/Login';
import Registration from './screen/Login/Registration';
import Logs from './screen/Logs/Logs';
import ProfileStatus from './screen/profile/ProfileStatus';
import { stateContext } from './utils/stateContext';
import { inializeState, StateReducer } from './utils/StateReducer';
import Loading from './common/Loading';
import Download from './screen/Downlaod/Download';


type StateType = {
  isLogin: any;
  token: any;
  user_Data: any;
  networkError: boolean;
  popupData: any;
  user: null | { role_id?: number }; // Update this if `role_id` is inside `user`
  chat: boolean;
  resetPassword: null;
  logsView: boolean;
  role_id?: number; // Add role_id here if it's part of the root state
};


function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_DEPLOY_URL;


  // const [showSidebar, setShowSidebar] = useState(true);
  const [state, dispatch] = useReducer<React.Reducer< any, any>>(
    StateReducer,
    inializeState
  );
  // console.log(state.role_id);
  // how to access the role id


  
  // console.log(state.isLogin);
  // console.log(state.networkError);

  const chatboxContentRef = useRef<HTMLDivElement>(null);
  // Scroll to the bottom whenever the location changes (page navigation)
  useEffect(() => {
    if (chatboxContentRef.current) {
      chatboxContentRef.current.scrollTop = chatboxContentRef.current.scrollHeight;
    }
  }, []); // Replace 'messages' with the state/variable that triggers the scroll

  return (
    <stateContext.Provider value={{ state, dispatch }}>
      <Router>
        {state.isLogin ? (
          <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-2 col-md-2 ps-0">
                <Sidebar />
              </div>
              <div className="col-lg-10 col-md-10">
                <div className=''>
                <Breadcrumb />
                </div>

                {/* <div className='col-lg-12 col-md-10'> */}
                <div className="outLet ps-2 scroll"    style={{ marginTop: "40px" }}>
                  <ProfileStatus />
                  <div className="pt-2">
                    {state?.networkError ? <NetworkError /> :
                    <Routes>
                      {/* <Route path="/" element={<Home />} /> */}
                      <Route path="/" element={<Chatbot />} />
                      <Route path="/Logs" element={<Logs />} />
                      <Route path="/Download" element={<Download />} />
                      <Route path="/LoadingPage" element={<Loading />} />
                      {/* <Route path="/profile" element={<Profile />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/kids" element={<Profile />} />
                      <Route path="/geography" element={<Profile />} />
                      <Route path="/demographics" element={<Profile />} />
                      <Route path="/psychographics" element={<Profile />} />
                      <Route path="/behavioral" element={<Profile />} />
                      <Route path="/Reports" element={<Reports />} />
                      <Route path="/score_card" element={<Reports />} />
                      <Route path="/finance_plan" element={<Reports />} />
                      <Route path="/settings" element={<Settings />} /> */}
                    </Routes>
}
                  </div>
                </div>
                <div>

                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
            {state?.popupData?.showPopup && <Popup/>}
            </>
         ) : ( 
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Register" element={<Registration />} />
            <Route path="/EmailAddress" element={<EmailAddress />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            {/* <Route path="*" element={<Login />} /> */}
          </Routes>
         )} 
      </Router>
    </stateContext.Provider>
  );
}

export default App;
