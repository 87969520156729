// Function to convert UTC time to IST
export function convertUtcToIst(utcDateStr: string): string {
  // Create a Date object from the UTC date string
  const utcDate = new Date(utcDateStr);

  // IST offset is UTC+5:30
  const istOffsetMs = 5 * 60 * 60 * 1000 + 30 * 60 * 1000; // 5 hours 30 minutes in milliseconds

  // Adjust the date to IST
  const istDate = new Date(utcDate.getTime() + istOffsetMs);

  // Format the IST date into YYYY-MM-DD HH:MM:SS
  const year = istDate.getUTCFullYear();
  const month = String(istDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(istDate.getUTCDate()).padStart(2, '0');
  const hour = String(istDate.getUTCHours()).padStart(2, '0');
  const minute = String(istDate.getUTCMinutes()).padStart(2, '0');
  const second = String(istDate.getUTCSeconds()).padStart(2, '0');
  console.log(year, month, day, hour, minute, second);
  

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}


export function formatUtcDate(utcDateStr: string): string {
  // Create a Date object from the UTC date string
  const utcDate = new Date(utcDateStr);

  // Format the UTC date into YYYY-MM-DD HH:MM:SS
  const year = utcDate.getUTCFullYear();
  const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getUTCDate()).padStart(2, '0');
  const hour = String(utcDate.getUTCHours()).padStart(2, '0');
  const minute = String(utcDate.getUTCMinutes()).padStart(2, '0');
  const second = String(utcDate.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}


export function getTimeDifference(indianRailwayTimeStr: string): string {
  // Remove 'Z' from the timestamp
  const localTimeStr = indianRailwayTimeStr.replace('Z', '');

  // Convert the input string to a Date object directly, assuming it's in IST
  const date: Date = new Date(localTimeStr);

  // Get the current time
  const now = new Date();

  // Calculate the difference in milliseconds
  const differenceInMillis: number = now.getTime() - date.getTime();

  // Convert milliseconds to seconds
  const differenceInSeconds: number = Math.floor(differenceInMillis / 1000);
  
  // Convert seconds to minutes, hours, and days
  const secondsInMinute = 60;
  const secondsInHour = 3600;
  const secondsInDay = 86400;

  if (differenceInSeconds < secondsInMinute) {
    return `${differenceInSeconds} seconds ago`;
  } else if (differenceInSeconds < secondsInHour) {
    const differenceInMinutes: number = Math.floor(differenceInSeconds / secondsInMinute);
    return `${differenceInMinutes} minutes ago`;
  } else if (differenceInSeconds < secondsInDay) {
    const differenceInHours: number = Math.floor(differenceInSeconds / secondsInHour);
    return `${differenceInHours} hours ago`;
  } else {
    const differenceInDays: number = Math.floor(differenceInSeconds / secondsInDay);
    return `${differenceInDays} days ago`;
  }
}


export function howManyDaysAgo(date: string): string {
  // Parse the input date string to a Date object
  const parsedDate = new Date(date);

  // Get the current date and time
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = now.getTime() - parsedDate.getTime();

  // Calculate the difference in seconds, minutes, hours, days, weeks, months, and years
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); // Approximation: 30 days in a month
  const years = Math.floor(days / 365); // Approximation: 365 days in a year
  console.log(years, months, weeks, days, hours, minutes, seconds);
  

  // Determine the appropriate return value
  if (seconds < 60) {
    console.log(seconds);
    
    return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  } else if (hours < 24) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  } else if (days < 7) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  } else if (days < 30) {
    return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
  } else if (days < 365) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  } else {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  }
}

