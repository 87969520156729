// import axios from '../api/axios';
// import { stateContext } from './stateContext';
// import React from 'react'
// import { useContext } from 'react';

// const useRefreshToken = () => {
//     const {
//         state: {
//           logsView,
//           user_Data: { user_name, role_id, user_id },
//         },
//         dispatch,
//       } = useContext(stateContext);
//     const refresh = async() => {
//         const response = await axios.get('/refresh',{withCredentials:true});
//         const newAccessToken = response.data.accessToken;

//         dispatch({
//             type: "TOKEN",
//             payload: newAccessToken
//           });
//           const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
//           userDetails.token = newAccessToken;
//           sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
//           return newAccessToken
//     }
//     return refresh;
// }

// export default useRefreshToken

import { useContext } from 'react';
import { useNavigate } from "react-router-dom"; // For navigation
import axios, { axiosPrivate } from '../api/axios';
import { stateContext } from './stateContext';


const useRefreshToken = () => {
    const {
        state: {
          token,
          user_Data: { user_name, role_id, user_id },
        },
        dispatch,
    } = useContext(stateContext);

    const utcDate = new Date().toUTCString();
    

    const navigate = useNavigate(); // Hook for navigation

    const refresh = async () => {
        try {
            const response = await axios.get('/refresh', { withCredentials: true });
            const newAccessToken = response.data.accessToken;

            dispatch({
                type: "TOKEN",
                payload: newAccessToken,
            });

            const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
            userDetails.token = newAccessToken;
            sessionStorage.setItem("userDetails", JSON.stringify(userDetails));

            return newAccessToken;
        } catch (error) {
            await axiosPrivate.put(
                `/users/logout/${user_id}`,
                {
                  logout_time: utcDate,
                }
            );
            await axiosPrivate.patch(`${process.env.REACT_APP_BASE_URL}/logout`);
            console.error("Failed to refresh token: ", error);
            dispatch({ type: "LOGOUT" });
            navigate("/")
            throw error; // Propagate the error to the interceptor
        }
    };

    return refresh;
};

export default useRefreshToken;

